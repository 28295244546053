export default {
  data() {
    return {};
  },

  methods: {
    initialNames(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0].charAt(0);
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase();
      }

      return fullname;
    },
    formatUserName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0] + " ";
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase() + ".";
      }

      return fullname;
    },
    formatFechaSimple(value) {
      if (!value) return "--/--/--";
      return moment(value).format("DD/MM/YYYY");
    },
   formatMoneda(value) {
      if(!value) return 0
      return new Intl.NumberFormat('es-PE',{style: "decimal", currency:"PEN", minimumFractionDigits:2}).format(value)
    },
  
    formatFecha(value){
      if(!value) return "--/--/--"
      return moment(value).format('DD MMM YYYY')
    }
  },
};
