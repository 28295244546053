<template>
  <v-card elevation="0">
    <v-card-text class="black--text">
      <v-img
        width="150"
        class="mx-auto my-3"
        :src="require('@/assets/gestic-2.svg')"
      ></v-img>
      <div class="text-caption text-center font-weight-medium">
        Bienvenido al Sistema de gestión de equipos TIC! 👋🏻
      </div>

      <v-alert
        dismissible
        dense
        text
        prominent
        v-if="errorLogin.estado"
        color="red"
      >
        <small>{{ errorLogin.message }}</small>
      </v-alert>
      <v-form class="mt-2" v-model="validateform">
        <div class="py-3">
          <v-text-field
            type="email"
            outlined
            dense
            hide-details="auto"
            label="Email"
            v-model="loginFileds.email"
            :rules="[rules.email, rules.required]"
            @keyup.enter="loginUser"
          ></v-text-field>
        </div>

        <div class="py-3">
          <v-text-field
            v-model="loginFileds.password"
            :type="passHide ? 'password' : 'text'"
            outlined
            dense
            hide-details="auto"
            label="Contraseña"
            :rules="[rules.required]"
            @keyup.enter="loginUser"
          >
            <template v-slot:append>
              <v-btn icon x-small @click="passHide = !passHide">
                <v-icon class="clAzulCTM">
                  {{ passHide ? "fa-solid fa-eye" : "fa-solid fa-eye-slash" }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>

        <span
          ><a @click="switchComponent('FormRestorePass')"
            >Olvidaste la contraseña?</a
          ></span
        >

        <div class="py-3">
          <v-btn
            block
            elevation="0"
            class="bgAzulCTM white--text"
            :disabled="!validateform"
            @click="loginUser"
          >
            Iniciar Sesión
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "FormLogin",

  data() {
    return {
      validateform: true,
      passHide: true,
    };
  },

  computed: {
    ...mapState("utiles", ["rules"]),
    ...mapState("login", ["loginFileds", "errorLogin", "overlay"]),
  },

  methods: {
    ...mapMutations("login", ["switchComponent"]),
    ...mapActions("login", ["loginUser"]),
  },
};
</script>
