import utiles from "@/utiles";
import restorepass from "./restorepass";

import store from "@/store";
import router from "@/router";
import axios from "axios";




export default {
  namespaced: true,
  modules: {
    utiles,
    restorepass,
  },
  state: {
    loggeIn: false,
    loading: false,
    loginFileds: {
      email: "",
      password: "",
    },
    errorLogin: {
      estado : false,
      message : ""
    },
    overlay: false,
    
    loginComponents: "FormLogin",

    userUpdate: {
      avatar: "",
    },

    
  },
  mutations: {
    switchComponent(state, val) {
      state.loginComponents = val;
    },
    setToken(state, payload) {
      store.state.token = payload;
    },
    setUser(state, {user, token}) {
      store.state.user = Object.assign({}, user, {token: token});
      setTimeout(() => {
        store.state.overlay = false;
      }, 1000);
    },

    
    logout(state) {
      state.overlay = true;
      store.state.user = Object.assign({}, store.state.userDefault);
      location.reload();
    },

    badErrorAuth(state, error) {
      console.log(error);
      if (error.response.status == 401) {
        state.overlay = true;
        store.state.token = null;
        store.state.user = null;
        location.reload();
      }
    },

    /*AGREGAR AVATAR */

    addAvatarInput(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg"
        )
      ) { 
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, jpeg",
          icon: "error",
        });
        state.userUpdate.avatar = null;
        return;
      }
      state.userUpdate.avatar = event;
    },
  },
  actions: {
    loginUser({ commit, state }) {
      commit("utiles/resetConfigAxios");
      store.state.overlay = true;
      state.utiles.configAxios.url = state.utiles.apiUrl + "login";
      state.utiles.configAxios.method = "POST";
      state.utiles.configAxios.data = state.loginFileds;
      axios(state.utiles.configAxios)
        .then((res) => {
          commit("setToken", res.data.token);
          commit("setUser", {user:res.data.user, token: res.data.token});
          //commit("setInfoUser", res.data.user);

          state.errorLogin = {
            estado : false
          };
          state.loggeIn = true;
          router.push({ name: "home" });
        })
        .catch((error) => {
          if(error &&  error.response.status == 400){
            state.errorLogin = {
              estado : true,
              message: "Correo o Contraseña incorrecto!"
            };
          }
          state.loggeIn = false;
          store.state.overlay = false;
          commit("badErrorAuth", error);

          if(error && error.response.status == 500){
            state.errorLogin = {
              estado : true,
              message: "Usuario suspendido"
            };
          }

        });
    },

    getDataUser({ commit, dispatch, state }, token) {
      store.state.overlay = true;
      if (state.user) {
        commit("setUser", state.user);
      } else {
        commit("logout");
      }
    },


    logout({ commit, state }) {
      store.state.overlay = true;
      commit("utiles/resetConfigAxios");
      state.utiles.configAxios.url = state.utiles.apiUrl + "logout";
      state.utiles.configAxios.method = "POST";
      state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(state.utiles.configAxios)
        .then((res) => {
          if (res.status == 201) {
            commit("logout");
          }
        })
        .catch((error) => {
          console.log("ERRORR", error);
          store.state.overlay = false;
          commit("badErrorAuth", error);
        });
    },

    /* SUBIR AVATAR */

    addavatar({ commit, state }) {
      //store.state.overlay = true;
      let data = new FormData();
      data.append("iduser", store.state.user.iduser);
      data.append("avatar", state.userUpdate.avatar);
      state.utiles.configAxios.url = state.utiles.apiUrl + "addavatar";
      state.utiles.configAxios.method = "POST";
      state.utiles.configAxios.data = data;
      state.utiles.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
 
      axios(state.utiles.configAxios)
        .then((res) => {
          console.log(res);
          store.commit("setInfoUserAvatar", res.data.user.avatar);
          //location.reload();
        })
        .catch((error) => console.log(error));
    },

    /*Eliminar avatar*/

    deleteAvatar({ commit, state }) {
      swal({
        title: "¿Desea Eliminar la foto de perfil?",
        dangerMode: true,
        buttons: true,
        icon: "warning",
      }).then((confirma) => {
        if (confirma) {
          //store.state.overlay = true;
          let data = { iduser: store.state.user.iduser };
          state.utiles.configAxios.url = state.utiles.apiUrl + "deleteavatar";
          state.utiles.configAxios.method = "POST";
          state.utiles.configAxios.data = data;
          state.utiles.configAxios.headers.Authorization =
            "Bearer " + store.state.token;

          axios(state.utiles.configAxios)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                store.commit("setInfoUserAvatar", res.data.user.avatar);
                //location.reload();
              }
            })

            .catch((error) => console.log(error));
        }
      });
    },
  },

  getters: {
    
  },
};
