import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
export default {
  namespaced: true,
  state: {
    listestudiantes: [],
    findestudiante: {
      nombre: "",
      carrera: "",
      admision: "",
    },
    filter: {
      dni: "",
      nombre: "",
      sede: "",
    },
  },
  mutations: {
    setEstudiantes(state, payload) {
      state.listestudiantes = payload;
    },
  },
  actions: {
    /**
     * BUSCAR ESTUIANTE POR DNI EN API SIGE
     */

    findDniEstudiante({ commit, state }, dni) {
      store.commit("utiles/resetConfigAxios");
      store.state.asignaciones.loadingfindni = true;
      store.state.utiles.configAxios.url =
        "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" + dni;
      store.state.utiles.configAxios.headers.Authorization =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
      if (dni.trim() == "") {
        store.state.asignaciones.loadingfindni = false;
        return;
      }
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 200) {
            store.state.asignaciones.asignacion.dni = res.data.result.cDni;
            store.state.asignaciones.asignacion.nombre = res.data.result.nomalu;
            store.state.asignaciones.asignacion.carrera =
              res.data.result.specialtyName;
            var yeradmin = res.data.result.period.substr(0, 4);
            var periodAdmin = res.data.result.period.substr(
              4,
              res.data.result.period.length - 1
            );
            store.state.asignaciones.asignacion.admision =
              yeradmin + "-" + periodAdmin;
          }

          store.state.asignaciones.loadingfindni = false;

          store.commit('asignaciones/setAlertAsignacion', {type: 'success', message: "Estudiante encontrado! - complete los datos faltantes"} )
        })
        .catch((error) => {
          console.log(error);
          store.state.asignaciones.asignacion.nombre = '';
          store.state.asignaciones.asignacion.carrera = '';
          store.state.asignaciones.asignacion.admision = '';
          store.state.asignaciones.loadingfindni = false;
          store.commit('asignaciones/setAlertAsignacion', {type: 'error', message: "No se encontró al estudiante! - complete los datos faltantes"} )
        });
    },
  },
  getters: {},
};
