<template>
  <div>
    <background-system />
    <v-row style="position: relative" class="mx-1 mx-md-4">
      <v-col cols="12" class="pb-0 d-flex justify-space-between">
        <span class="title-page" v-if="registerUser.iduser == -1">
          <b>Registrar Usuario</b>
          <small> | Creación de cuentas </small>
        </span>
        <span v-else class="title-page">
          <b>Editar Usuario</b>
          <small> | Modificar datos </small>
        </span>

        <div >
          <router-link
            :to="{ name: 'gestionusuarios' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon left>mdi-list-status</v-icon> Lista Usuarios
            </v-btn>
          </router-link>

          <router-link
            :to="{ name: 'home' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn fab x-small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon>mdi-home-circle</v-icon></v-btn
            >
          </router-link>
        </div>
      </v-col>

      <v-col cols="12" lg="6" class="mx-auto">
        <v-card elevation="4">
          <v-card-text class="pa-5">
            <v-form v-model="formvalidate">
              <div class="text-h5 black--text font-weight-bold">
                {{
                  registerUser.iduser != -1
                    ? "Editar Usuario"
                    : "Formulario de Registro"
                }}
              </div>
              <span>Todos los datos son obligatorios</span>
              <v-row class="black--text mt-2">
                <v-col cols="12" md="6">
                  <label for="dni">DNI:</label>
                  <v-text-field
                    hide-details="auto"
                    dense
                    outlined
                    :rules="[rules.required, rules.onlyNumber, rules.dni]"
                    v-model="registerUser.dni"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="email">Email:</label>
                  <v-text-field
                    type="text"
                    hide-details="auto"
                    dense
                    outlined
                    :rules="[rules.required, rules.email]"
                    v-model="registerUser.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="nombre">Nombre:</label>
                  <v-text-field
                    type="text"
                    hide-details="auto"
                    dense
                    outlined
                    :rules="[rules.required]"
                    v-model="registerUser.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="nombre">Cargo:</label>
                  <v-text-field
                    type="text"
                    hide-details="auto"
                    dense
                    outlined
                    :rules="[rules.required]"
                    v-model="registerUser.cargo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" >
                  <label for="password">Contraseña:</label>
                  <v-text-field
                  v-if="registerUser.iduser == -1"
                    :type="hidepass ? 'text' : 'password'"
                    hide-details="auto"
                    dense
                    outlined
                    :rules="[rules.required]"
                    v-model="registerUser.password_confirmation"
                  >
                    <template v-slot:append>
                      <v-btn
                        x-small
                        rounded
                        elevation="0"
                        @click="hidepass = !hidepass"
                        ><v-icon>{{
                          hidepass ? "mdi-eye-off" : "mdi-eye"
                        }}</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                  <v-text-field
                  v-else
                    :type="hidepass ? 'text' : 'password'"
                    hide-details="auto"
                    dense
                    outlined
                    v-model="registerUser.password_confirmation"
                  >
                    <template v-slot:append>
                      <v-btn
                        x-small
                        rounded
                        elevation="0"
                        @click="hidepass = !hidepass"
                        ><v-icon>{{
                          hidepass ? "mdi-eye-off" : "mdi-eye"
                        }}</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                  <small v-if="registerUser.iduser != -1">Dejar en blanco si no desea cambiar la contraseña</small>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0 d-flex justify-space-between flex-wrap"
                >
                  <div class="text-subtitle-1 black--text font-weight-bold">
                    Roles y Permisos
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <label for="roles">Selecciona un rol:</label>
                  <v-select
                    hide-details="auto"
                    dense
                    outlined
                    label="Roles disponibles"
                    :items="listroles"
                    item-text="name"
                    item-value="idrole"
                    :rules="[rules.required]"
                    v-model="registerUser.role_id"
                    @change="getPermisosOfRol()"
                  ></v-select>

                  <div class="d-flex mt-2">
                    <router-link :to="{name:'gestionusuarios'}" style="text-decoration:none;">
                    <v-btn elevation="0" @click="clearRoles()">
                      CANCELAR
                    </v-btn>
                    </router-link>

                    <v-btn
                      @click="createUser"
                      elevation="0"
                      color="blue white--text"
                      :disabled="!formvalidate"
                    >
                      {{registerUser.iduser == -1? 'CREAR' : 'GUARDAR' }}
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <label for=""> Permisos del Rol </label>
                  <v-list dense>
                    <v-list-item
                      dense
                      v-for="(p, i) in listPermisosOfRol"
                      :key="i"
                      class="px-0"
                    >
                      <v-list-item-avatar class="ma-0">
                        <v-avatar
                          size="25"
                          color="light-green accent-3 green--text text--accent-4"
                        >
                          <v-icon size="15" color="green">mdi-check</v-icon>
                        </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        {{ p.name }}
                        <v-list-item-subtitle>
                          {{ p.action }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item></v-list
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <!--overlay-->

          <v-fade-transition>
            <v-overlay v-if="loadingEdit" absolute color="#ffffff" opacity="1">
              <div class="text-h6 font-weight-medium text-center d-block black--text"> Cargando información...</div>
              <v-progress-circular
                :size="50"
                color="primary"
                class="d-block my-2 mx-auto"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
          </v-fade-transition>

          <!--end overlay-->
        </v-card>
      </v-col>
    </v-row>

 
  </div>
</template>

<script>
import backgroundSystem from "@/components/backgroundSystem.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";
export default {
  components: { backgroundSystem },
  name: "RegistrarUsuario",
  data() {
    return {
      hidepass: false,
      formvalidate: true,
      listPermisosOfRol: []
    };
  },
  watch: {

  },
  computed: {
    ...mapState("utiles", ["rules"]),
    ...mapState("users", ["loadingEdit"]),
    ...mapState("users/register", ["registerUser"]),
    ...mapState("users/roles", [
      "listroles",
    ]),

  },

  methods: {
    ...mapMutations("users/register",["clearRegisterUser"]),
    ...mapMutations("users/roles",["clearRoles"]),
    ...mapActions("users/roles", ["getRoles", ]),
    ...mapActions("users/register", ["createUser"]),
    ...mapActions("users", ["showUser"]),
   

    getPermisosOfRol() {
      let rol = this.listroles.filter((rol) => {
        return rol.idrole === this.registerUser.role_id;
      });

      if (rol.length == 0) return;
      this.listPermisosOfRol = rol[0].permisos;
    },
  },

  mounted() {
    this.getRoles();
    this.clearRoles();
    this.clearRegisterUser();

    if (this.$route.params.id) {
      this.showUser(this.$route.params.id);
    }
  },
  created() {
    if (!this.$can("create", "usuarios") || !this.$can("update", "usuarios")) {
      router.push({ name: "home" });
    }
  },
};
</script>
