<template>
  <div>
    <v-app-bar app class="white" elevation="0" :fixed="fixedTop" dense>
      <v-container fluid class="d-flex align-center">
        <v-app-bar-title>
          <div class="d-flex">
            <v-img
              v-if="!$can('read', 'usuarios')"
              width="30"
              contain
              class="mr-1"
              :src="require('@/assets/logo-300x300.png')"
            ></v-img>
            <router-link :to="{ name: 'home' }">
              <v-img
                width="100"
                :src="require('@/assets/gestic-2.svg')"
              ></v-img>
            </router-link>
          </div>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <div v-if="$can('read', 'usuarios')">
          <v-btn  text small :to="{ name: 'gestionusuarios' }" class="mx-2 d-none d-lg-flex" color="indigo darken-4">
            <v-icon left size="20">mdi-account-group</v-icon>
            <span>USUARIOS</span>
          </v-btn>
          <v-btn icon color="indigo darken-4 mx-2" class="d-lg-none" :to="{ name: 'gestionusuarios' }"> <v-icon size="23">mdi-account-group</v-icon></v-btn>
        </div>


        <div v-if="$can('read', 'usuarios')">
          <v-btn text small :to="{ name: 'rolesdeusuario' }" class="mx-2 d-none d-lg-flex" color="indigo darken-4">
          <v-icon left size="20">mdi-account-tie</v-icon> <span>ROLES</span></v-btn>
          <v-btn icon color="indigo darken-4" class="mx-2 d-lg-none" :to="{ name: 'rolesdeusuario' }"> <v-icon size="23">mdi-account-tie</v-icon></v-btn>
        </div>
       
        <!--<NotificacionesComponent class="mr-5"/>-->
        <avatarMenu></avatarMenu>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import avatarMenu from "./avatar-menu.vue";
import NotificacionesComponent from "./NotificacionesComponent.vue";

export default {
  components: { avatarMenu, NotificacionesComponent },
  props: {
    fixedTop: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("login", ["user", "overlay"]),
    ...mapState(["rules", "nav"]),
  },
  methods: {
    ...mapActions("login", ["logout", "getDataUser"]),
    ...mapActions("login", ["updatepassworduser"]),
  },
};
</script>
