<template>
  <div>
    <backgroundSystem/>
    <v-row style="position: relative;" class="mx-1 mx-md-4 mt-1">
        <v-col cols="12" class="pb-0 d-flex justify-space-between">
        <span class="title-page">
          <b>Roles de usuario</b>
          <small> | permisos y accesos</small>
        </span>

        <div> 

          <router-link
            :to="{ name: 'home' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn fab x-small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon>mdi-home-circle</v-icon></v-btn
            >
          </router-link>
        </div>
      </v-col>
    <v-col cols="12" class="mx-auto mt-3">
      <v-card
        elevation="0"
        class="black--text"
      >
        <v-row class="px-6 py-2">
          <v-col cols="12" md="5" class="grey lighten-3 rounded-lg">
            <div class="pa-3 text-subtitle-1 font-weight-medium">
              {{ editIndex == -1 ? "Crear Nuevo Rol" : "Edital Rol" }}
            </div>

            <div>
              <label for="">Nombre del Rol:</label>
              <v-text-field
                outlined
                hide-details="auto"
                dense
                class="white"
                v-model="nombreRol"
                :rules="[rules.required]"
              ></v-text-field>
            </div>

            <div class="pa-3">
              <div class="text-subtitle-1 font-weight-medium">
                Permisos a secciones
              </div>
              <small>Seleccione que seccion puede tener acceso</small>
            </div>

            <div class="black--text white rounded-lg pa-2 my-2 elevation-4">
              <v-select
                :items="listpermisos"
                item-text="name"
                item-value="subject"
                hide-details="auto"
                color="blue accent-3"
                dense
                outlined
                label="Selecciona una sección"
                class="my-3"
                v-model="permiso.subject"
                @change="getActions()"
              >
              </v-select>
              <v-select
                :items="action"
                hide-details="auto"
                dense
                multiple
                small-chips
                outlined
                label="Selecciona las acciones"
                class="my-3"
                color="blue accent-3"
                v-model="permiso.action"
                clearable
              >
              </v-select>

              <v-btn
                @click="addpermision"
                :disabled="permiso.action.length == 0"
                elevation="0"
                small
                color="light-blue white--text"
                ><v-icon left>mdi-plus</v-icon> Agregar</v-btn
              >
            </div>

            <div style="height: 500px; overflow-y: auto;">
              <div
              class="d-flex justify-space-between black--text white rounded-lg pa-2 my-2 elevation-3"
              v-for="(permiso, index) in permisos"
              :key="index"
            >
              <div>
                <div class="text-subtitle-1 font-weight-medium">
                  {{ namePermiso(permiso.subject) }}
                </div>
                <v-chip
                  class="mr-2 bgAzulCTM white--text"
                  small
                  v-for="(action, index) in permiso.action"
                  :key="index"
                  >{{ action }}</v-chip
                >
              </div>
              <v-btn
                @click="deletepermission(permiso)"
                icon
                x-small
                elevation="0"
                class="mr-2 white--text red"
                ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
              >
            </div>

            </div>
            <div class="d-flex justify-end pa-2">
              <v-btn elevation="0" @click="clearRoles()"> CANCELAR </v-btn>

              <v-btn
                @click="saveRol"
                elevation="0"
                color="blue white--text"
                :disabled="nombreRol == '' || permisos.length == 0"
              >
                GUARDAR
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <div class="text-subtitle-1 font-weight-medium">Lista de Roles</div>
            <v-simple-table fixed-header height="900px">
              <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 5px;">N°</th>
                      <th style="width: 40px;"></th>
                      <th style="width: 100px;">Nombre Rol</th>
                      <th style="width: 600px;">Secciones Permitas</th>
                      <th style="width: 40px;">CREAR</th>
                      <th style="width: 40px;">LEER</th>
                      <th style="width: 40px;">EDITAR</th>
                      <th style="width: 40px;">BORRAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(rol, index) in listroles" >
                    <tr v-if="rol">
                      <td :rowspan="rol.permisos.length + 1">{{index + 1}}</td>
                      <td :rowspan="rol.permisos.length + 1">
                        <div class="d-flex">
                          <v-btn
                            @click="editRol(rol)"
                            small
                            elevation="0"
                            class="mr-2 clAzulCTM amber"
                            icon
                            ><v-icon size="20">mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn
                            @click="deleteRol(rol.idrole)"
                            small
                            elevation="0"
                            class="mr-2 clAzulCTM red"
                            icon
                            ><v-icon size="20" color="white">mdi-delete</v-icon>
                        </v-btn>
                        </div>


                      </td>
                      <td :rowspan="rol.permisos.length + 1"><div class="font-weight-bold">{{ rol.name }}</div></td>
                    </tr>
                    <template v-for="(permiso, a) in rol.permisos">
                      <tr>
                        <td><div class="font-weight-bold text-uppercase text-caption">{{ permiso.name }}</div></td>
                        <td>
                          <v-avatar size="28" class="green accent-3" v-if="permiso.action.includes('create')"><v-icon size="20" class="font-weight-bold white--text">mdi-check</v-icon></v-avatar>
                          <v-avatar size="28" class="red accent-3" v-else><v-icon size="20" class="font-weight-bold white--text">mdi-close</v-icon></v-avatar>
                        </td>
                        <td>
                          <v-avatar size="28" class="green accent-3" v-if="permiso.action.includes('read')"><v-icon size="20" class="font-weight-bold white--text">mdi-check</v-icon></v-avatar>
                          <v-avatar size="28" class="red accent-3" v-else><v-icon size="20" class="font-weight-bold white--text">mdi-close</v-icon></v-avatar>
                        </td>
                        <td>
                          <v-avatar size="28" class="green accent-3" v-if="permiso.action.includes('update')"><v-icon size="20" class="font-weight-bold white--text">mdi-check</v-icon></v-avatar>
                          <v-avatar size="28" class="red accent-3" v-else><v-icon size="20" class="font-weight-bold white--text">mdi-close</v-icon></v-avatar>
                        </td>
                        <td>
                          <v-avatar size="28" class="green accent-3" v-if="permiso.action.includes('delete')"><v-icon size="20" class="font-weight-bold white--text">mdi-check</v-icon></v-avatar>
                          <v-avatar size="28" class="red accent-3" v-else><v-icon size="20" class="font-weight-bold white--text">mdi-close</v-icon></v-avatar>
                          </td>
                      </tr>
                    </template>

                    </template>
                  </tbody>
              </template>
            </v-simple-table>



          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </div>
  
</template>

<script>
import backgroundSystem from "@/components/backgroundSystem.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";
export default {
  components: { backgroundSystem },
  name: "RolesUsuario",
  data() {
    return {
      formvalidate: true,
      addpermiso: {
        pagina: "",
        permisos: [],
      },
      headers: [
        {
          text: "N°",
          value: "index",
          width: "30px",
          sortable: false,
        },
        {
          text: "Nombre Rol",
          value: "name",
          width: "40px",
          sortable: false,
        },
        {
          text: "Permisos",
          value: "permisos",
          width: "300px",
          sortable: false,
        },
        {
          text: "",
          value: "buttons",
          width: "80px",
          sortable: false,
        },
      ],
      permissionsChecks: [
        {
          text: "Leer",
          value: "read",
        },
        {
          text: "Crear",
          value: "create",
        },
        {
          text: "Editar",
          value: "update",
        },
        {
          text: "Eliminar",
          value: "delete",
        },
      ],

      listPermisosOfRol: [],
    };
  },
  watch: {
    dialogGestion: {
      handler(val) {
        if (!val) this.clearRoles();
      },
    },
  },
  computed: {
    ...mapState("utiles", ["rules"]),
    ...mapState("users/roles", [
      "rolname",
      "permisos",
      "permiso",
      "action",
      "listpermisos",
      "listroles",
      "loading",
      "editIndex",
    ]),
    nombreRol: {
      get() {
        return this.rolname;
      },

      set(val) {
        this.setRolname(val);
      },
    },
  },

  methods: {
    ...mapMutations("users/roles", [
      "getActions",
      "addpermision",
      "deletepermission",
      "setRolname",
      "clearRoles",
      "editRol",
    ]),

    ...mapActions("users/roles", ["saveRol", "getRoles", "deleteRol"]),

    namePermiso(name) {
      let permiso = this.listpermisos.filter((route) => {
        return route.subject == name;
      });
      if (permiso.length == 0) return;
      return permiso[0].name;
    },

    getPermisosOfRol() {
      let rol = this.listroles.filter((rol) => {
        return rol.idrole === this.registerUser.role_id;
      });

      if (rol.length == 0) return;
      this.listPermisosOfRol = rol[0].permisos;
    },
  },

  mounted() {
    this.getRoles();
    this.clearRoles();

    if (this.$route.params.id) {
      this.showUser(this.$route.params.id);
    }
  },
  created() {
    if (!this.$can("create", "usuarios") || !this.$can("update", "usuarios")) {
      router.push({ name: "home" });
    }
  },
};
</script>
