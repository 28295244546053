import Vue from "vue";
import Vuex from "vuex";

import VuexPersistence from "vuex-persist";

import { Ability } from "@casl/ability";
import abilityPlugin from "@/plugins/defineAbility";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key:'vuem',
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user }),
});


import utiles from "@/utiles";
const store =  new Vuex.Store({
  modules:{
    utiles
  },
  plugins: [ 
    vuexLocal.plugin,
    abilityPlugin
  ], 

  state:{
    user: {
      nombre: "",
      email: "",
      token: ""
    },
    userDefault: {
      nombre: "",
      email: "",
      token: ""
    },
    nav: {
      drawer: true
    },
    overlay: false
  },
  getters: {
    getterLogginIn(state) { 
      return state.user != null && state.user.token;
    },
    ability() {
      return new Ability([], {
        subjectName(subject) {
          return !subject || typeof subject === "string"
            ? subject
            : subject[TYPE_KEY];
        },
      });
    },
  },
  mutations: {
    setInfoUserAvatar(state, avatar) {
      state.user.avatar = avatar;
    },

    saludo(){
      console.log('saludo desde store')
    }
  },
  actions: {},
});



try {
  console.log('INGRESE AQUI')
  // Intenta cargar los datos de Vuex persistente
  vuexLocal.restore(store);
}catch(e){
  // Si hay un error, borra el contenido del localstorage
  localStorage.removeItem('vuex');
}

export default store;
