import axios from "axios";
import store from "@/store/index";
import swal from "sweetalert";
import router from "@/router";


export default {
  namespaced: true,

  state:{
    idequipo: -1,
    equipo: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
      tipo_documento: "",
      num_documento: ""
    },
    equipoDefault: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
      tipo_documento: "",
      num_documento: ""
    },

    loading: false,
  },

  mutations:{
    cleanEquipo(state) {
        state.equipo = null
        state.equipo = Object.assign({}, state.equipoDefault);
        state.idequipo = -1;
        state.searchserie = ""
      },
  },

  actions:{
    saveEquipo({ commit, state }) {
        state.loading = true;
        let msgSwal = "";
        if (state.idequipo == -1) {
          store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "equipo";
          store.state.utiles.configAxios.method = "POST";
          msgSwal = "Equipo registrado correctamente!";
        } else {
          store.state.utiles.configAxios.url =
            store.state.utiles.apiUrl + "equipo/" + state.equipo.idequipo;
          store.state.utiles.configAxios.method = "PUT";
          msgSwal = "Equipo actualizado correctamente!";
        }
        store.state.utiles.configAxios.data = state.equipo;
        store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            state.loading = false;
            if (res.status == 204) {
              swal({
                title: "Ya existe un equipo con la serie ",
                text: state.equipo.serie,
                icon: "error",
              });
              return;
            }
            swal({
              title: msgSwal,
              text: "Serie: " + state.equipo.serie,
              icon: "success",
            });
            state.equipo = Object.assign({}, state.equipoDefault);
            setTimeout(() => {
              router.push({ name: "inventariogeneral" });
            }, 300);
          })
          .catch(function (error) {        
            state.loading = false;  
          });
      },

      showEquipo({ commit, state }, idequipo) {
        state.loading = true;
        store.commit("utiles/resetConfigAxios");
        state.idequipo = idequipo;
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "equipo/" + idequipo;
        store.state.utiles.configAxios.method = "GET";
        store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            state.equipo = Object.assign({}, res.data.equipo);
            state.loading = false;
          })
          .catch(function (error) {
            state.loading = false;
            swal({
              title: "El equipo no fue encontrado",
              icon: "error"
            })
            router.push({name: 'inventariogeneral'})
          });
      },
  
  }
}