import axios from "axios";
import store from "@/store/index";
import swal from "sweetalert";
import router from "@/router";


export default {
  namespaced: true,
  state: {
    permiso: {
      name: "",
      subject: "",
      action: [],
    },
    permisoDefault: {
      name: "",
      subject: "",
      action: [],
    },
    editIndex: -1,
    rolname: "",
    action: [],
    permisos: [],
    listroles: [],
    permisosEliminados: [],
    loading: false,
    loadingoverly: false,
    listpermisos: [
      {
        name: "Gestión de Equipos",
        subject: "Equipos",
        action: ["read", "create", "update", "delete"],
      },
      {
        name: "Historial de Equipos",
        subject: "HistorialE",
        action: ["read"],
      },
      {
        name: "Gestion de Equipos Asignados",
        subject: "GestionEquiposAsignados",
        action: ["create", "update", "delete"],
      },
      {
        name: "Gestión de Asignaciones",
        subject: "Asignaciones",
        action: ["read", "create", "update", "delete"],
      },
      {
        name: "Gestión de Asignaciones Colaboradores - RRHH",
        subject: "AsignacionesRRHH",
        action: ["read"],
      },
      {
        name: "Devoluciones",
        subject: "Devoluciones",
        action: ["read", "create", "update", "delete"],
      },
      {
        name: "Registro de Asignados",
        subject: "RegistroAsignados",
        action: ["read"],
      },
      {
        name: "Usuarios",
        subject: "usuarios",
        action: ["read", "create", "update", "delete"],
      },
    ],
  },

  mutations: {
    setRoles(state, payload) {
      state.listroles = payload;
    },
    setRolname(state, name) {
      state.rolname = name;
    },
    clearRoles(state) {
      state.rolname = "";
      state.permisos = [];
      state.editIndex = -1;
      state.permisosEliminados = [];
    },

    getActions(state) {
      state.permiso.action = [];
      var filter = state.listpermisos.filter((permiso) => {
        return permiso.subject === state.permiso.subject;
      });

      if (filter.length == 0) return [];
      state.action = filter[0].action;
      state.permiso.name = filter[0].name;

      if (filter[0].action.length == 1) {
        state.permiso.action = filter[0].action;
      }
    },

    addpermision(state) {
      let condicion = state.permisos.find(
        (elem) => elem.subject == state.permiso.subject
      );
      console.log(state.permisos);
      if (condicion) {
        swal({
          title: "Ya se ha registrado este rol",
          icon: "warning",
        });

        return;
      }
      state.permisos.push(Object.assign({}, state.permiso));
      state.permiso = Object.assign({}, state.permisoDefault);
      state.action = [];
    },

    deletepermission(state, permiso) {
      swal({
        title: "¿Desea eliminar este Permiso?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) return;

        let index = state.permisos.indexOf(permiso);

        if (index != -1) {
          state.permisos.splice(index, 1);
          state.permisosEliminados.push(permiso);
          console.log(state.permisosEliminados);
        }
      });
    },

    editRol(state, editRol) {
      state.rolname = editRol.name;
      var parsePermisos = [];
      editRol.permisos.forEach((perm) => {
        parsePermisos.push({
          idpermiso: perm.idpermiso,
          name: perm.name,
          subject: perm.subject,
          action: perm.action,
        });
      });
      state.permisos = parsePermisos;
      state.editIndex = editRol.idrole;
    },
  },

  actions: {
    getRoles({ commit, state }) {
      //utiles.commit("resetConfigAxios");
      store.commit("utiles/resetConfigAxios")
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "roles";
      store.state.utiles.configAxios.method = "GET";
      axios(store.state.utiles.configAxios)
        .then((res) => {
          commit("setRoles", res.data.data);
          console.log(res.data)
        })
        .catch((error) => {});
    },

    saveRol({ commit, dispatch, state }) {
      state.loading = true;
      store.commit("utiles/resetConfigAxios");
      var parsePermisos = [];
      state.permisos.forEach((perm) => {
        parsePermisos.push({
          idpermiso: perm.idpermiso,
          name: perm.name,
          subject: perm.subject,
          action: perm.action.join(),
        });
      });
      var data = null;
      var msgswal = "Se creó nuevo Rol";
      if (state.editIndex == -1) {
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "rol";
        store.state.utiles.configAxios.method = "POST";
        data = Object.assign(
          {},
          { name: state.rolname },
          { permisos: parsePermisos }
        );
      } else {
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "updaterol";
        store.state.utiles.configAxios.method = "PUT";
        msgswal = "Se actualizó el Rol";
        data = Object.assign(
          {},
          { idrole: state.editIndex, name: state.rolname },
          { permisos: parsePermisos },
          { permisosEliminados: state.permisosEliminados }
        );
      }

      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;


        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 205) {
            swal({
              title: "Ya existe un rol con el mismo nombre",
              icon: "error",
            });
            return;
          }
          if (res.status == 201) {
            swal({
              title: msgswal,
              icon: "success",
            });

            dispatch("getRoles");
            commit("clearRoles");
          }
          state.loading = false;
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    },

    deleteRol({commit, dispatch, state}, idRole ){
      store.commit("utiles/resetConfigAxios")
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "rol";
      store.state.utiles.configAxios.method = "DELETE";
      store.state.utiles.configAxios.data = {idrole: idRole}
      store.state.utiles.configAxios.headers.Authorization ='Bearer ' + store.state.user.token;

      swal({
        title: "¿Desea eliminar este Rol?",
        text: "Confirme la acción",
        icon: "warning",
        dangerMode: true,
        buttons: ['CANCELAR', 'ELIMINAR']
      })
      .then(confirmar => {
        if(!confirmar){ return }
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(store.state.utiles.configAxios)
        .then(resp => {
          if(resp.status == 200){
            swal({
              title: "Se eliminó correctamente!",
              icon: "success",
            });

            dispatch("getRoles");
            commit("clearRoles");
          }
        })
        .catch(error => {
          console.log(error)
          swal({
            title: "Ocurrió un error!",
            icon: "error",
          });
        })

      })
    }
  },
};
