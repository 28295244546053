import axios from "axios";
import store from "@/store";
import swal from "sweetalert";
import Vue from "vue";
import utiles from "@/utiles";
export default {

  namespaced: true,
  state: {
    defaultItemsPerPage: 60,
    asign: {
      fechaasignacion: "",
      proyecto: "",
      evidenciadeentrega: "",
      comentario: null,
      estado: "",
      user_id: "",
      estudiante: {
        idestudiante: -1,
        dni: "",
        nombre: "",
        telefono: "",
        direccion: "",
        carrera: "",
        admision: "",
        sede: "",
        condicion: "",
      },
      colaborador: {
        idcolaborador: -1,
        dni: "",
        nombre: "",
        telefono: "",
        jefatura: "",
      },
      equiposAsignados: [],
    },
    asignDefault: {
      fechaasignacion: "",
      proyecto: "",
      evidenciadeentrega: "",
      comentario: null,
      estado: "",
      user_id: "",
      estudiante: {
        idestudiante: -1,
        dni: "",
        nombre: "",
        telefono: "",
        direccion: "",
        carrera: "",
        admision: "",
        sede: "",
        condicion: "",
      },
      colaborador: {
        idcolaborador: -1,
        dni: "",
        nombre: "",
        telefono: "",
        jefatura: "",
      },
      equiposAsignados: [],
    },
    listdevoluciones: [],
    dni: "",
    searchAsignacion:{
      nombreDni: "",
      serie: ""
    },
    loadingdevolucion: false,
    loading: false,
    equiposDevueltos: {},
    filterdevolucion: {
      tipoasignado: "estudiante",
      dni: "",
      nombre: "",
      admision: "",
      sede: "",
      carrera: "",
      estadoequipo: "",
      fechasignacion: "",
      proyecto: "",
      serie: "",
    },
    filterdevolucionDefault: {
      tipoasignado: "estudiante",
      dni: "",
      nombre: "",
      admision: "",
      sede: "",
      carrera: "",
      estadoequipo: "",
      fechasignacion: "",
      proyecto: "",
      serie: "",
    },
    pagination: {
      itemsPerPage: 20,
      itemsLength: 0,
      page: 1,
    },
    orderByComumnFor:{
      column: "",
      type : ""
    },

    loadingPDFReporte: false
  },
  mutations: {

    setDefaulIPP(state, payload){
      state.defaultItemsPerPage = payload;
    },
    setDni(state, payload) {
      state.dni = payload;
    },

    resetAsign(state) {
      state.asign = Object.assign({}, state.asignDefault);
      state.dni = "";
    },

    cambioEstadoEquipo(state, { equipo, estado }) {
      Vue.set(state.equiposDevueltos, equipo.serie, {
        estado: estado,
        serie: equipo.serie,
      });
    },

    setDevoluciones(state, payload) {
      state.listdevoluciones = payload;
    },

    resetDevoluciones(state) {
      state.listdevoluciones = [];
    },

    clearFilters(state){
      state.filterdevolucion = Object.assign({}, state.filterdevolucionDefault);
    }
  },
  actions: {
    getAsignacion({ commit, state }) {
      store.commit("utiles/resetConfigAxios");
      if (state.searchAsignacion.nombreDni == "" && state.searchAsignacion.serie == "") {
        swal({ title: "Debe ingresar un dato para buscar", icon: "warning" });
        return;
      }
      state.loading = true;
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "showasignacion";
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.params = state.searchAsignacion;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            if (res.data.data.length > 0) {
              state.asign = Object.assign({}, res.data.data[0]);
            }
            if (res.data.data.length == 0 || res.status == 205) {
              commit("resetAsign");
              swal({
                title: "No se encontró asignación",
                icon: "warning",
              });
            }
          }
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          commit("resetAsign");
        });
    },

    devolverEquipoAsignado({ commit, dispatch, state }, infoequipo) {
      store.commit("utiles/resetConfigAxios");
      infoequipo.user_id = store.state.user.iduser
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "devolverequipo";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = infoequipo;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      console.log(infoequipo)
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 205) {
            swal({
              title: "Ha ocurrido un error",
              text: "vuelve a intentar, actualiza la página",
              icon: "error",
            });

            return;
          }
          swal({
            title: "Se guardó la información correctamente",
            icon: "success",
          });

          setTimeout(() => {
            dispatch("getAsignacion", false);
          }, 300);
        })
        .catch((error) => {
        });
    },

    /**
     *
     * Cambiar estado de equipo desde la lista de devoluciones
     */
    cambiarEEDevoluciones({ commit, dispatch, state }, infoequipo) {
      store.commit("utiles/resetConfigAxios");
      infoequipo.user_id = store.state.user.iduser
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "devolverequipo";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = infoequipo;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 205) {
            swal({
              title: "Ha ocurrido un error",
              text: "vuelve a intentar, actualiza la página",
              icon: "error",
            });

            return;
          }
          swal({
            title: "Se guardó la información correctamente",
            icon: "success",
          });
 
          setTimeout(() => {
            dispatch("axiosDevoluciones", {page: 1, itemsPerPage: state.defaultItemsPerPage});
          }, 300);
        })
        .catch((error) => {
        });
    },

    /**
     *
     * Dar de baja el equipo
     */

    dardebajaEquipo({ commit, dispatch, state }, infoequipo) {
      store.commit("utiles/resetConfigAxios");
      infoequipo.user_id = store.state.user.iduser
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "dardebaja";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = infoequipo;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      swal({
        title: "¿Desea dar de baja este equipo?",
        text: "Ya no podrá cambiar su estado",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(confirma => {
        if(!confirma) return 
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 205) {
              swal({
                title: "Ha ocurrido un error",
                text: "vuelve a intentar, actualiza la página",
                icon: "error",
              });
  
              return;
            }
            swal({
              title: "Se dio de baja el equipo",
              icon: "success",
            });
            setTimeout(() => {
              dispatch("axiosDevoluciones", {page: 1, itemsPerPage: state.defaultItemsPerPage});
            }, 300);
          })
          .catch((error) => {
          });
      })

    },

    axiosDevoluciones({ commit, state }, { page = 1, itemsPerPage }) {
      store.commit("utiles/resetConfigAxios");
      commit("setDevoluciones", []);
      state.loadingdevolucion = true;
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl +
        "devoluciones?page=" +
        page +
        "&itemsperpage=" +
        itemsPerPage;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.params = state.filterdevolucion;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
      .then((res) => {
          state.loadingdevolucion = false;
          commit("setDevoluciones", res.data.data);
          state.pagination.itemsPerPage = parseInt(res.data.meta.per_page);
          state.pagination.itemsLength = parseInt(res.data.meta.total); 
          console.log(res.data.data);
        })
        .catch((error) => {
          state.loadingdevolucion = false;
          console.log(error)
        });
    }, 

    downloadReporte({commit, state}){
      var data = Object.assign({}, state.filterdevolucion, state.orderByComumnFor, { user: store.state.user})
      console.log(data)
      state.loadingPDFReporte = true
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "reportedevoluciones";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.responseType = 'blob'
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(store.state.utiles.configAxios)
      .then((res) => {
        let blob = new Blob([res.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'reporte-devoluciones.pdf';
        link.click();
        state.loadingPDFReporte = false
      })
      .catch((error) => {
        state.loadingPDFReporte = false
        swal({
          title: "Ha ocurrido un error",
          text: "Intenta actualizar la página",
          icon: "error"
        })
      });
    }
  
  },
  getters: {},
};

