<template>
  <div>
    <v-menu
    v-model="menu"
      offset-y
      offset-x
      v-if="user.nombre"
      origin="center center"
      transition="scale-transition"
      max-width="250"
      min-width="250"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          bottom
          color="green accent-4"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-avatar v-if="user.avatar" size="40" v-bind="attrs" v-on="on">
            <v-img :src="user.avatar"></v-img>
          </v-avatar>
          <v-avatar color="bgAzulCTM" v-else size="40" v-bind="attrs" v-on="on">
            <span class="font-weight-bold white--text">{{
              initialNames(user.nombre)
            }}</span>
          </v-avatar>
        </v-badge>
      </template>
      <v-card>
        <v-card-text class="text-center px-3 pt-3 pb-0">
          <v-avatar
            v-if="user.avatar"
            class="rounded-md"
            size="100"
            style="overflow: visible"
          >
            <v-img :src="user.avatar"></v-img>
            <v-btn
              @click="
                deleteAvatar();
                menu = false;
              "
              absolute
              icon
              small
              class="red lighten-5"
              style="bottom: -3px; left: -1px"
            >
              <v-icon size="18" color="red darken-2"
                >mdi-delete-outline</v-icon
              ></v-btn
            >
          </v-avatar>
          <v-avatar v-else size="100" color="bgAzulCTM" class="flex-column align-center justify-center">
            <label for="inputAvatar" style="cursor:pointer;" >
              <v-icon color="white">mdi-camera</v-icon>
            </label>
            <v-file-input id="inputAvatar" accept="image/png, image/jpeg, image/bmp" class="white--text d-none" @change="addAvatarInput($event); menu=false" prepend-icon="mdi-camera"></v-file-input>
          </v-avatar>
        </v-card-text>
        <div class="pb-2 text-center">
          <div class="d-block font-weight-medium text-h6">
            {{ user.nombre }}
          </div>
          <small class="d-block grey--text text--darken-1">{{
            user.cargo
          }}</small>
        </div>

        <v-divider></v-divider>
        <v-list dense>
          <v-list-item dense @click="dialogChangePass = true">
            <v-list-item-avatar class="ma-1"
              ><v-icon>mdi-key</v-icon>
            </v-list-item-avatar>
            <v-list-item-title> Cambiar contraseña</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="logout">
            <v-list-item-avatar class="ma-1"
              ><v-icon>mdi-logout</v-icon>
            </v-list-item-avatar>
            <v-list-item-title> Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!--dialogo cambiar contraseña-->
    <v-dialog v-model="dialogChangePass" persistent width="400" max-width="400">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between blue accent-3 white--text"
        >
          <div class="text-subtitle-1">
            <v-avatar color="white" size="40" class="mr-2">
              <v-icon size="30">mdi-lock</v-icon>
            </v-avatar>
            Cambiar contraseña
          </div>
          <v-btn icon @click="dialogChangePass = false" dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="black--text py-4">
          <v-form v-model="validateform">
            <div>
              <label for="">Contraseña actual:</label>
              <v-text-field
                v-model="updatepassword.currentpassword"
                :type="iconpass ? 'text' : 'password'"
                outlined
                hide-details="auto"
                dense
                :rules="[rules.required]"
              >
                <template v-slot:append
                  ><v-btn icon @click="iconpass = !iconpass"
                    ><v-icon>{{
                      iconpass ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon></v-btn
                  ></template
                >
              </v-text-field>
            </div>
            <div>
              <label for="">Nueva contraseña:</label>
              <v-text-field
                v-model="updatepassword.newpassword"
                :type="iconnewpass ? 'text' : 'password'"
                outlined
                hide-details="auto"
                dense
                :rules="[rules.required]"
              >
                <template v-slot:append
                  ><v-btn icon @click="iconnewpass = !iconnewpass"
                    ><v-icon>{{
                      iconnewpass ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon></v-btn
                  ></template
                >
              </v-text-field>
            </div>
            <v-btn
              @click="updatepassworduser"
              :disabled="!validateform"
              block
              color="blue accent-3 white--text"
              elevation="0"
              class="my-2"
              >Cambiar contraseña</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--dialogo cambiar contraseña-->

   
  </div>
</template>

<script>
import Filters from '@/Filters';
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  mixins:[Filters],
  name: "avatarMenu",

  data() {
    return {
      dialogChangePass: false,
      iconpass: false,
      iconnewpass: false,
      validateform: true,
      menu: false,
    };
  },
  watch: {
    dialogChangePass: {
      handler(val) {
        if (!val) {
          this.clearUpdatePassword();
        }
      },
    },

    "userUpdate.avatar":{
      handler(val){
        if(val){
           this.addavatar();
        }
      }
    }
  },
  computed: {
    ...mapState("login/restorepass", ["updatepassword"]),
    ...mapState("login", [ "userUpdate"]),
    ...mapState(["user", "overlay"]),
    ...mapState('utiles', ['rules']),
    ...mapGetters(["getterLogginIn"]),
  },

  methods: {
    ...mapActions("login",["logout", "getDataUser", "addavatar", "deleteAvatar"]),
    ...mapMutations("login/restorepass", ["clearUpdatePassword"]),
    ...mapMutations("login", ["addAvatarInput"]),
    ...mapActions("login/restorepass", ["updatepassworduser"]), 
  },
 
  mounted() {
    this.clearUpdatePassword();
  },
};
</script>
