export default {
  namespaced: true,
  state: {
    apiUrl: "https://gestic-api.cetemin.edu.pe/api/",
    //apiUrl: "http://localhost:8000/api/",
    //apiUrl: "http://192.168.100.242:8000/api/",
    rules: {
      required: (value) => !!value || "Campo Requerido.",
      counter: (value) => value.length <= 20 || "Max 20 caracteres",
      textmedium: (value) => value.length <= 50 || "Max 50 caracteres",
      textsimple: (value) => value.length >= 5 || "Min 5 caracteres",
      celular: (value) => value.length == 9 || "Número de celular no válido",
      dni: (value) => value.length == 8 || "DNI: Solo debe tener 8 dígitos",
      onlyNumber: (value) => {
        const pattern = /^[0-9]*(\.?)[0-9]+$/;
        return pattern.test(value) || "Solo ingrese numeros";
      },
      mayorcero: (value) =>
        parseInt(value) > 0 || "El monto debe ser mayor que cero",
      notnegative: (value) =>
        parseInt(value) > -1 || "No se aceptan números negativos",
      email: (value) => {
        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(value) || "Email no válido";
      },
    },

    configAxios: {
      method: "",
      url: "",
      responseType: "",
      data: {},
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
    configAxiosDefault: {
      method: "",
      url: "",
      responseType: "",
      data: {},
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },


    estadoInicialEquipo: ["Disponible", "Por preparar", "De baja"],

    /**
     * Usado para los filtros
     * Son todos los estados del equipo
     */
    estadosEquipo: [
      "Disponible",
      "Por preparar",
      "De baja",
      "En uso",
      "Revision Pendiente",
      "Observación",
      "Perdida-Robo",
      "No devuelto",
    ],
    estadoAsignacion: ["activo", "finalizado"],

    /**
     * Estados para registrar una devolución
     * solo 3 son posibles en la Categoria DEVUELTO:
     * Buen estado
     * Revision Pendiente
     * Dañado
     */
    estadoEquipo: [
      "Buen estado",
      {
        text: "Revisión Pendiente",
        value: "Revision Pendiente",
      },
      {
        text: "Dañado (Observado)",
        value: "Observación",
      },
      {
        text: "Equipo Bueno (pero Incompleto)",
        value: "Incompleto",
      }
    ],

    /**
     * Son todos los estados de un equipo devuelto
     * SE USA EN EL FILTRO DEL ÁREA DEVOLUCIONES
     */
    estadoEquipoDevuelto: [
      {
        text: "Buen estado (devuelto)",
        value: "Buen estado",
      },
      {
        text: "Revisión Pendiente",
        value: "Revision Pendiente",
      },
      {
        text: "De baja (Inutilizable)",
        value: "De baja",
      },
      
      "Perdida-Robo",
      "No devuelto",
      "Observación",
      "Incompleto",
    ],
    motivoQuitarEquipo: ["No le corresponde", "Error de registro"],
    motivoNoDevolverEquipo: ["Perdida-Robo", "No lo trajo"],
    motivoAsignarEquipo: ["Cambio", "Entrega Reciente"],
    tipoEquipo: ["Laptop", "Modem", "PC",  "Smartphone", "Tablet", "Proyector", "TV"],
    condicionEquipo: ["Propio", "Alquiler"],
    sedes: [
      "ABQ",
      "IRQ",
      "AREQ",
      "CAJAM",
      {
        text: "REMOTO (solo colaboradores)",
        value: "REMOTO",
      },
    ],
    condicionEstudiante: ["Becado", "Regular"],
    tipodeentrega : [
      {
        text: "Alquiler o Prestamo",
        value: "alquiler"
      },
      {
        text: "Beneficio por beca",
        value: "beca"
      },
    ]

    
  },

  mutations:{
    resetConfigAxios(state) {
      state.configAxios = Object.assign({}, state.configAxiosDefault);
    },


  }
};
