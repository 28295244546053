<template>
  <div class="d-inline-block">
    <v-chip
      small
      class="font-weight-medium"
      color="blue lighten-4 blue--text text--darken-3"
      v-if="estado == 'Disponible'"
      ><v-icon left size="15" color="blue">mdi-check-bold</v-icon
      >{{ estado }}</v-chip
    >
    <v-chip
      small
      class=" font-weight-medium"
      color="deep-purple lighten-5 deep-purple--text darken-4--text"
      v-if="estado == 'Por preparar'"
      ><v-icon left size="15" color="deep-purple">mdi-cog</v-icon
      >{{ estado }}</v-chip
    >
    <v-chip
      small
      class=" font-weight-medium"
      color="grey lighten-4 grey--text text--darken-3"
      v-if="estado == 'De baja'"
      ><v-icon left size="15" color="grey">mdi-close-thick</v-icon
      >{{ estado }}</v-chip
    >
    <v-chip
      small
      class=" font-weight-medium"
      color="green accent-1 green--text text--accent-4"
      v-if="estado == 'En uso'"
      ><v-icon left size="15" color="green">mdi-star-check</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      class=" font-weight-medium"
      color="green accent-1 green--text text--accent-4"
      v-if="estado == 'Buen estado'"
      ><v-icon left size="15" color="green">mdi-check-all</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      class=" font-weight-medium"
      color="red lighten-4 red--text"
      v-if="estado == 'Observación'"
      ><v-icon left size="15" color="red">mdi-lightbulb-on</v-icon
      >{{ estado }}</v-chip
    >


    <v-chip
      small
      class=" font-weight-medium"
      color="red lighten-4 red--text"
      v-if="estado == 'Incompleto'"
      ><v-icon left size="15" color="red">mdi-lightbulb-on</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      class=" font-weight-medium"
      color="red lighten-4 red--text"
      v-if="estado == 'No devuelto'"
      ><v-icon left size="15" color="red">mdi-cancel</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      color="yellow lighten-4 orange--text darken-3--text"
      class="font-weight-medium "
      v-if="estado == 'Revision Pendiente'"
      ><v-icon left size="15" color="orange">mdi-stethoscope</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      color="grey lighten-2 black--text"
      class="font-weight-medium "
      v-if="estado == 'Perdida-Robo'"
      ><v-icon left size="15" color="black">mdi-robber</v-icon
      >{{ estado }}</v-chip
    >

    <v-chip
      small
      class=" font-weight-medium"
      color="red lighten-4 red--text"
      v-if="estado == 'No existe'"
      ><v-icon left size="15" color="red">mdi-minus-circle</v-icon
      >No exitste</v-chip
    >
  </div>
</template>
<script>
export default {
    name: "chipEstado",
    props: {
        estado:{
            type: String,
            required: true
        }
    }
}
</script>