<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-badge color="red darken-4" content="6" overlap>
          <v-icon v-on="on" size="30" class="clAzulCTM"
            >mdi-bell-outline</v-icon
          >
        </v-badge>
      </template>
      <v-card width="400" elevation="0">
        <v-card-subtitle class="pb-0"> Notificaciones </v-card-subtitle>
        <v-card-text class="pa-2 black--text mt-2">
          <div class="pa-4 grey lighten-3">
            <h4>Se realizó una devolución</h4>
            <span
              >El estudiante MEDINA RIVAS BELBAO BALMES, devolvió los siguientes
              equipos:</span
            >
            <v-simple-table dense class="transparent">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Serie</th>
                    <th>Marca | Modelo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>5CG936C15Z</td>
                    <td>HP 14-ck0031la</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex">
              <span>19/03/2023</span>
              <v-spacer></v-spacer>
              <v-chip small><v-avatar class="bgCTM"><v-icon>mdi-user</v-icon></v-avatar> Daniel A.</v-chip>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
    };
  },
  methods: {},
};
</script>
