import Module from './Module.vue';
import LoginView from './views/LoginView'
const moduleRoute = {

    path: "/login",
    component: Module,
    children: [

        { 
            path: "/login", 
            component: LoginView,
            name: "login",
            meta: {
                requiresGuest: true,
                title: "Iniciar Sesión"
            }
          },

          {
            path: "/restorepassword/:token/:mail",
            name: "restorepassword",
            component: () =>
              import(/* webpackChunkName: "about" */ "./views/Restorepassword"),
            meta: {
              requiresGuest: true,
              title: "Restaurar contraseña",
              action: "read",
              resource: "restore",
            },
          },
    ]
}

  
export default router => {
    router.addRoute(moduleRoute);
  };