
export default  (store) => {
  const ability = store.getters.ability;

  ability.update([{ actions: 'read', subject: 'login' }]); 
 

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case "login/setUser":
        var permisos = [];
        mutation.payload.user.rol.permisos.forEach((perm) => {
          permisos.push({
            subject: perm.subject,
            action: perm.action,
          });
        });
        ability.update(permisos)
        break;
      case "login/logout":
        ability.update([{ actions: 'read', subject: 'login' }]);
        break;
    }
  });
 



};
