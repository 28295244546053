import store from "@/store";
import utiles from "@/utiles";
import axios from "axios";
import swal from "sweetalert";

import register from "./register";
import roles from "./roles";

export default {
  namespaced: true,
  modules: {
    utiles,
    register,
    roles,
  },
  state: {
    listusers: [],
    pagination: {
      page: 1,
      itemsPerPage: 1,
      pageStart: 1,
      pageStop: 1,
      pageCount: 1,
      itemsLength: 1,
    },
    loading: false,
    loadingEdit: false,
  },

  mutations: {
    setListUser(state, payload) {
      state.listusers = payload;
    },

    badErrorAuth(state, error) {
      console.log(error);
      if (error.response.status == 401) {
        state.overlay = true;
        store.state.token = null;
        store.state.user = null;
        location.reload();
      }
    },

    clearUpdatePassword(state) {
      state.updatepassword = Object.assign({}, state.updatepasswordDefault);
    },

    clearRestoreEmail(state) {
      state.restorepass = Object.assign({}, { email: "" });
    },
  },

  actions: {
    getAllUser({ commit, state }, { page = 1, itemsPerPage = 30 }) {
      state.loading = true;
      commit("utiles/resetConfigAxios");
      state.utiles.configAxios.url =
        state.utiles.apiUrl +
        "users?page=" +
        page +
        "&itemsperpage=" +
        itemsPerPage;
      state.utiles.configAxios.method = "GET";
      state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(state.utiles.configAxios)
        .then((res) => {
          commit("setListUser", res.data.data);
          state.pagination.itemsPerPage = parseInt(res.data.meta.per_page);
          state.pagination.pageCount = parseInt(res.data.meta.last_page);
          state.pagination.itemsLength = parseInt(res.data.meta.total);
          state.pagination.pageStart = parseInt(res.data.meta.from);
          state.pagination.pageStop = parseInt(res.data.meta.to);
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          commit("badErrorAuth", error);
        });
    },

    showUser({ commit, state }, id) {
      if (!id) {
        router.push({ name: "gestionusuarios" });
      }
      state.loadingEdit = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "user/" + id;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 200) {
            state.register.registerUser.iduser = res.data.user.iduser;
            state.register.registerUser.dni = res.data.user.dni;
            state.register.registerUser.email = res.data.user.email;
            state.register.registerUser.name = res.data.user.nombre;
            state.register.registerUser.avatar = res.data.user.avatar;
            state.register.registerUser.role_id = res.data.user.rol.idrole;
            state.register.registerUser.cargo = res.data.user.cargo;
          }
          state.loadingEdit = false;
        })
        .catch((error) => {
          state.loadingEdit = false;
        });
    },

    suspenderUsuario({ commit, dispatch, state }, user) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "suspender-usuario";
      store.state.utiles.configAxios.method = "PUT";
      store.state.utiles.configAxios.data = { iduser: user.iduser };
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: user.estadoUser == 'active' ? "¿Desea suspender al usuario?" : "¿Desea activar este usuario?",
        text: "confirme la acción",
        dangerMode: true,
        buttons: ["CANCELAR", "ACEPTAR"],
        icon: "warning",
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }

        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(store.state.utiles.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              swal({
                title: "Se realizó la petición",
                icon: "success",
              });
              dispatch('getAllUser', { page : 1, itemsPerPage : 30 })
            }
          })
          .catch((error) => {
            console.error(error);
            swal({
              title: "Ha ocurrido un error",
              icon: "error",
            });
          });
      });
    },
  },
};
