import store from "@/store";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    countEquipos: {
      laptop: 0,
      desktop: 0,
    },

    countEstados:{
        laptop: 0,
        desktop: 0,
        en_uso: 0,
        disponible: 0,
        por_preparar: 0,
        de_baja: 0,
        perdida_robo: 0,
        revision_pendiente: 0,
        no_devuelto: 0,
        observacion: 0
    }
  },


  mutations:{

    setCountEquipos(state, payload){
        state.countEquipos = Object.assign({}, payload)
    },


    setCountEstados(state, payload){
        state.countEstados = Object.assign({}, payload)
    }
  },

  actions: {
    getCountEquipos({ commit, state }) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "metricas";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

        axios(store.state.utiles.configAxios)
        .then(res =>{
            if(res.status == 200){
                commit('setCountEquipos', res.data.equipos)
                commit('setCountEstados', res.data.estados)
            }
        })
        .catch(error => console.log(error))
    },
  },
};
