import store from "@/store";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import swal from "sweetalert";


export default{
    namespaced: true,
    state:{
        componentMasivo: "uploadPlantilla",
        masivoData: {
            user_id : 0,
            file_plantilla: null
        },

        asignacion:{
          index: 0,
          nombre: "",
          tipo: "",
          cargo: "",
          jefatura: "",
          admision: "",
          carrera: "",
          sede: "",
          dni: "",
          comentario: "",
          condicion: "",
          equiposAsignados: [],
          equiposNoValidos: 0,
          estadoEquipo: "",
          fecha_entrega: "",
          lugar_entrega: "",
          estadoAsignacion: false,
          anteriorAsignacion: false,
          validacionDNI: false
        },
        asignacionDefault:{
          index: 0,
          nombre: "",
          tipo: "",
          cargo: "",
          jefatura: "",
          admision: "",
          carrera: "",
          sede: "",
          dni: "",
          comentario: "",
          condicion: "",
          equiposAsignados: [],
          equiposNoValidos: 0,
          estadoEquipo: "",
          fecha_entrega: "",
          lugar_entrega: "",
          estadoAsignacion: false,
          anteriorAsignacion: false,
          validacionDNI: false
        },

        listaAsignaciones: [],

        rowsListas: 0,


        reportes: [],
        total_saved: 0,
        
    },

    mutations:{
        setMasivoFile(state, event){
            if (
                event != null &&
                !(
                  event.type == "application/vnd.ms-excel" ||
                  event.type ==
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                )
              ) {
                swal({
                  title: "ARCHIVO NO PERMITIDO",
                  text: "Porfavor solo agregre archivos: Excel",
                  icon: "error",
                });
                state.masivoData.masivoData = null;
                swal({
                  title: "Solo se permiten documentos excel",
                  icon: "warning",
                });
                return;
              }
              state.masivoData.file_plantilla = event;
        },

        resetMasivas(state){
          state.masivoData.file_plantilla = null
          state.asignacion = Object.assign({}, state.asignacionDefault)
          state.listaAsignaciones = []
          state.componentMasivo= "uploadPlantilla"
          state.rowsListas = 0
          state.reportes = []
          state.total_saved = 0
        }
        
    },

    actions:{
        readDataFileExcel({ commit, dispatch, state }) {
            readXlsxFile(state.masivoData.file_plantilla)
            .then((rows) => {
                dispatch('dataConvertJson', rows)
              })
            .catch(error => {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error!"
                })
                state.componentMasivo = "upload-plantilla"
            })
    
        },


        async dataConvertJson({commit, dispatch, state}, data){
          if(data[0][0] != 'Cetemin000*'){
            swal({
              title: "Archivo no valido, ingresa la plantilla original",
              icon: "warning"
            })
            return
          }

          let dataExcel = data
          state.componentMasivo = 'loading'
          for(let i =3; i <= dataExcel.length -1; i++){
              state.asignacion = Object.assign({}, state.asignacionDefault)
              state.asignacion.index = i - 2
              if(dataExcel[i][0] == null || dataExcel[i][0] == " "){
                continue;
              }
              state.asignacion.dni = dataExcel[i][0]

              //Validar series de equipos
              var seriesEquiposEnTabla = []

              for(let e = 1; e <=5; e++){
                if(dataExcel[i][e] !== null){
                  seriesEquiposEnTabla.push(dataExcel[i][e])
                }
              }
              
              var responseEquipos = await dispatch('ValidarEquiposAsignados', seriesEquiposEnTabla )

              state.asignacion.equiposAsignados = responseEquipos
             
              state.asignacion.tipo = dataExcel[i][6] ? dataExcel[i][6] : 'estudiante'

              //Validar datos de estudiante / colaborador
              var estOrCola = null
              if(state.asignacion.tipo == 'estudiante'){
                estOrCola = await dispatch('getDNISige', state.asignacion.dni)
                state.asignacion.validacionDNI = estOrCola != null;
                state.asignacion.nombre = estOrCola ? estOrCola.nombrecompleto : 'NO NAME'
                state.asignacion.carrera = estOrCola ? estOrCola.carrera : 'NO CARRERA'
                state.asignacion.admision = estOrCola ? estOrCola.admision : 'NO ADMISION'
              }


              if(state.asignacion.tipo == 'colaborador'){
                estOrCola = await dispatch('getDNIReniec', state.asignacion.dni)
                state.asignacion.nombre = estOrCola.nombrecompleto ? estOrCola.nombrecompleto : 'NO NAME'
                state.asignacion.jefatura = dataExcel[i][9] ? dataExcel[i][9] : 'NO JEFATURA'
                state.asignacion.cargo = dataExcel[i][10] ? dataExcel[i][10] : 'NO CARGO'
              }

              state.asignacion.condicion = dataExcel[i][7] ? dataExcel[i][7] : 'Regular'
              state.asignacion.proyecto = state.asignacion.condicion == 'Becado' ? dataExcel[i][8] : null

              state.asignacion.sede = dataExcel[i][11] ?  dataExcel[i][11] : 'ABQ'
              state.asignacion.lugar_entrega = dataExcel[i][12] ? dataExcel[i][12] : 'Lima'
              state.asignacion.fecha_entrega = dataExcel[i][13] ? moment(dataExcel[i][13], "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss")
              state.asignacion.comentario = dataExcel[i][14]

              //Comprobar si el dni ya cuenta con una asignación anterior
              var lastAsign = await dispatch('getAnteriorAsignacion', {dni: state.asignacion.dni, tipoasignado: state.asignacion.tipo})
              
              state.asignacion.anteriorAsignacion = lastAsign!=null;

              if(!state.asignacion.anteriorAsignacion  && state.asignacion.nombre != 'NO NAME' && (state.asignacion.equiposAsignados.length > state.asignacion.equiposNoValidos)){
                state.asignacion.estadoAsignacion = true
              }

              state.listaAsignaciones.push(state.asignacion)
              state.rowsListas = ((i + 1) / dataExcel.length) * 100;
          }

          state.componentMasivo = "tablaResultados"

        },


        async getDNISige({commit, dispatch, state}, dni){
          store.commit("utiles/resetConfigAxios");
          store.state.utiles.configAxios.url =
          "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
          dni;
          store.state.utiles.configAxios.headers.Authorization =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
  
          const response = await axios(store.state.utiles.configAxios)
          .then((res) => {
          if (res.status == 200) {
              const obj = {};
              obj.nombrecompleto = res.data.result.nomalu;
              obj.carrera = res.data.result.specialtyName;
              var yeradmin = res.data.result.period.substr(0, 4);
              var periodAdmin = res.data.result.period.substr(
              4,
              res.data.result.period.length - 1
              );
              obj.admision = yeradmin + "-" + periodAdmin;
              return obj;
              }
          })
          .catch((error) => {
              console.log(error)
  
              return null
          });
  
          return response;
      },
  
      async getDNIReniec({commit, state}, dni){
        store.commit("utiles/resetConfigAxios");
          store.state.utiles.configAxios.url ="https://api.apirest.pe/api/getDniPremium"
          store.state.utiles.configAxios.method = 'POST'
          store.state.utiles.configAxios.data = { dni : dni}
          store.state.utiles.configAxios.headers.Authorization =
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhhYjYyMWVkNDBmMmVhMzlmMDg3M2Y2ZGQzOWIwZjQzMGFmZGE1ODBkY2JiYjk4ZDYyM2ZmOGM2NzJiNzQxN2JiZTZjNDQwMDc0MjZhYzZkIn0.eyJhdWQiOiIxIiwianRpIjoiOGFiNjIxZWQ0MGYyZWEzOWYwODczZjZkZDM5YjBmNDMwYWZkYTU4MGRjYmJiOThkNjIzZmY4YzY3MmI3NDE3YmJlNmM0NDAwNzQyNmFjNmQiLCJpYXQiOjE1NjY0MjMxODYsIm5iZiI6MTU2NjQyMzE4NiwiZXhwIjoxODgyMDQyMzg2LCJzdWIiOiIxNTUiLCJzY29wZXMiOlsiKiJdfQ.gMN_mZ7o3n_ODVX0h5kIU-xmN6HETZU98zXE2AZHywtb0Q7A35DfssNcr34wIN_TaPWHhXWn5MmYnTEhk20pGQb-1_ebGr2uZRdyiHlniY7rky7ZWlvsYDfp8p1gxh5gfEZbgTjQCZl0gJP6bh1yURzJSm3hcSYLgjR7oz6uYokV8FFSHIhrxLbrWBGapNVdqKRBELJ1clSNDRKH2CT2criscOtkXQEbZSY68CP4eTIoVk0C4-ghx1TlxSp4wMOV6_YgRc8JGMXSEpX3r9gZ1AcER3BTyfzRz0JlpXiE_F0C0NtQH8dFf1uYQv1d8S3wYKjBWAmn4QMGdsIphHcswfEpM9_o8gwBIdEzYm3vv5NJNAp79OGq9kHT7gtwiEV9cqdi5fgZ-nVvXmUN7wwi1ef87OKgP8j3K6AePB7R5W9ElqLwHy0O2j8urz992ZHFynMwLVd02L_ElIM9xeBDjblB-awg-RN9Krw29uXgYcsUdyOkmrmLON2Lg4-5De7-5g2cR-vyKiLCzRILvJBs-BmcrVo-UHkk99bIL3wUT6C9akMZ7crqRzbYBil5-Cln6WS6_9pMww0in6YhRm_jv-QY23xH5WZVWE3RwKCAE-zu4EeRWP4dnL2RbhH_SJO19EZVUSVj1frf9T7u6rGQUYegK7AxyApDGkqgSpFHB6Y"
          const response = await axios(store.state.utiles.configAxios)
          .then(resp =>{
              const obj = {}
            if(resp.status == 200){
              obj.nombrecompleto = resp.data.result.NombreCompleto;
              return obj;
            }
          })
          .catch(error => {
            console.log(error)
            return null
          })
  
          return response;
      },


      async ValidarEquiposAsignados({commit, dispatch, state}, series){
        var equiposAsignados = []
        for(const serie of series){
          var equipo = null
           equipo = await dispatch('getSerieEquipo', serie)
           if(equipo != null){  
              equiposAsignados.push(equipo)
              if(equipo.estadoEquipo != 'Disponible' && equipo.estadoEquipo != 'Por preparar'){
                state.asignacion.equiposNoValidos += 1
              }
              if(equipo.equipo_id == null){ state.asignacion.equiposNoValidos += 1 }
           }
        }
        return equiposAsignados
      },


      async getSerieEquipo({commit, state}, serie){
          store.commit("utiles/resetConfigAxios");
            store.state.utiles.configAxios.url = store.state.utiles.apiUrl + 'findserieequipo/' + serie
            store.state.utiles.configAxios.method = 'GET'
            store.state.utiles.configAxios.data = { serie : serie}
            store.state.utiles.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token
            const response = await axios(store.state.utiles.configAxios)
            .then(resp => {
              var obj = {}
               if(resp.status == 200){
                    obj.serie = resp.data.equipo !== null ? resp.data.equipo.serie : serie
                    obj.marca = resp.data.equipo !== null ? resp.data.equipo.marca  : 'no valid'
                    obj.modelo = resp.data.equipo !== null ? resp.data.equipo.modelo : 'no valid'
                    obj.estadoEquipo = resp.data.equipo !== null ? resp.data.equipo.estadoEquipo : 'No existe'
                    obj.equipo_id = resp.data.equipo !== null ? resp.data.equipo.idequipo : null

                    return obj
               }               
            })
            .catch(error => {
              console.log(error)
              return null
            })
    
            return response;
      },


      async getAnteriorAsignacion({commit, dispatch, state}, payload){
        store.commit("utiles/resetConfigAxios");
            store.state.utiles.configAxios.url = store.state.utiles.apiUrl + 'asignacion-dni'
            store.state.utiles.configAxios.method = 'POST'
            store.state.utiles.configAxios.data = { dni : payload.dni, tipoasignado: payload.tipoasignado}
            store.state.utiles.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token
            const response = await axios(store.state.utiles.configAxios)
            .then(resp => {
              console.log(resp)
              var obj = {}
               if(resp.status == 200 ){
                    obj = Object.assign({}, resp.data.asignacion)
                    return obj
               }

               return null;
            })
            .catch(error => {
             
              return null
            })
    
            return response;
      },

      saveAsignacionesAxios({commit, dispatch, state}){
        store.commit("utiles/resetConfigAxios");
            store.state.utiles.configAxios.url = store.state.utiles.apiUrl + 'setasignacionesmasivas'
            store.state.utiles.configAxios.method = 'POST'
            store.state.utiles.configAxios.data = {listaAsignaciones : state.listaAsignaciones, user_id: store.state.user.iduser}
            store.state.utiles.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token
            swal({
              title: "Cargando...",
              text: "Por favor, espera mientras se realiza la petición.",
              icon: "info",
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
              dangerMode: true,
            });
        axios(store.state.utiles.configAxios)
        .then(resp => {
          if(resp.status == 200){
            swal({
              title: "Terminó la carga",
              icon: "success"
            })
            console.log(resp)
            state.componentMasivo = 'resultado'
            state.reportes = resp.data.reportes
            state.total_saved = resp.data.total_guardados
          }
        })
        .catch(error => {
          console.log(error)
          swal({
            title: "Ocurrió un error",
            icon: "error"
          })
        })
      }
  
    }
}