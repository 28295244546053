import { render, staticRenderFns } from "./NotificacionesComponent.vue?vue&type=template&id=72e7511b&"
import script from "./NotificacionesComponent.vue?vue&type=script&lang=js&"
export * from "./NotificacionesComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports