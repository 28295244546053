<template>
  <v-app>
    <v-main class="gradient-bg">
      <v-container fluid class="pa-0" style="margin-bottom: 80px">
        <NavbarComponent v-if="getterLogginIn" />
        <router-view />
      </v-container>
      <v-footer color="#1E293B" class="white--text py-0">
        <v-container class="d-md-flex justify-space-between align-center">
          <div>
            <small class="d-block mb-0"
              >GESTIC v.2.0 - <b> CETEMIN {{ yearCurrent }}</b></small
            >
          </div>

          <div class="text-subtitle-2 font-weight-bold">
            Developed by Daniel Arellano - TIC
          </div>
        </v-container>
      </v-footer>
    </v-main>

    <v-overlay
      :value="overlay"
      color="bggeneral"
      class="text-center"
      opacity="1"
      z-index="1000"
    >
      <v-img
        width="80"
        class="mx-auto animate__animated animate__shakeX"
        :src="require('@/assets/logo-300x300.png')"
      ></v-img>
      <span
        class="white--text font-weight-bold mt-1 animate__animated animate__fadeIn"
        >CETEMIN</span
      >
    </v-overlay>
  </v-app>
</template>

<script>
import store from "./store";
import NavbarComponent from "@/components/Navbar/NavbarComponent.vue";
import NavDrawer from "@/components/NavigationDrawer.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    NavbarComponent,
    NavDrawer,
  },
  data: () => ({
    //
  }),

  computed: {
    ...mapGetters(["getterLogginIn"]),
    ...mapState(["overlay"]),
    yearCurrent() {
      return moment().format("YYYY");
    },
  },

  created() {
    if (store.getters.getterLogginIn) {
      store.commit("login/setUser", {
        user: store.state.user,
        token: store.state.user.token,
      });
    }
  },
};
</script>
<style>
.gradient-bg {
  /*background-image: linear-gradient(to right top, #58d68d, #57d498, #59d1a2, #5eceaa, #66cbb0, #5bc7b7, #52c2bc, #4dbdc1, #2eb6ca, #0aadd2, #0fa3d9, #3498db);
*/
  background-color: #D3D3E7 !important;
}

.bggeneral {
 /* background-image: linear-gradient(
    to right top,
    #58d68d,
    #57d498,
    #59d1a2,
    #5eceaa,
    #66cbb0,
    #5bc7b7,
    #52c2bc,
    #4dbdc1,
    #2eb6ca,
    #0aadd2,
    #0fa3d9,
    #3498db
  );*/
  background-color: #D3D3E7 !important;
}

.bggeneralGradiente {
  background: hsla(216, 96%, 56%, 1);

  background: linear-gradient(
    90deg,
    hsla(216, 96%, 56%, 1) 0%,
    hsla(178, 64%, 65%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(216, 96%, 56%, 1) 0%,
    hsla(178, 64%, 65%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(216, 96%, 56%, 1) 0%,
    hsla(178, 64%, 65%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2278FB", endColorstr="#6BDFDB", GradientType=1 );
  width: 100%;
  height: 100vh;
  position: fixed;
}
.title-page {
  font-size: 20px !important;
  color: #000000;
}
/* Color CETEMIN */
.bgAzulCTM {
  background-color: #0b2a4a !important;
}

.bgAzulCTMOpacity {
  background-color: #15395e !important;
}
.borderAzulCTM {
  border: 2px solid #0b2a4a !important;
}
.clAzulCTM {
  color: #0b2a4a !important;
}

/* theme sweetalert */
.swal-overlay {
  background: rgba(224, 243, 249, 0.8);
}
.swal-title,
.swal-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.swal-title {
  font-size: 20px;
}
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.filterWidth {
  color: #0b2a4a !important;
  font-weight: bold;
}
@media (min-width: 740px) {
  .filterWidth {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }
}

/**
 *  EFECTO DE OLA 
 */
</style>
