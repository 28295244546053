<template>
  <v-card elevation="0">
    <v-card-text>
       <v-img width="150" class="mx-auto mb-3" :src="require('@/assets/forgot-password.png')"
       :lazy-src="require('@/assets/forgot-password.png')"></v-img>
      <span class="black--text"
        >Ingresa tu email para poder recibir los pasos para restaurar la
        Contraseña</span
      >
      <v-form v-model="validateformpass">
       
        <div class="py-3">
          <v-text-field
            type="email"
            outlined
            dense
            hide-details="auto"
            label="Email"
            v-model="restorepass.email"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </div>
        <div class="py-3">
          <v-btn block elevation="0" 
          :disabled="!validateformpass"
          class="bgAzulCTM white--text" 
          :loading="loadingoverly"  
          @click="restorepassword">
            Enviar enlace
          </v-btn>
        </div>

        <v-btn  @click="switchComponent('FormLogin')" block text small>volver al login</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "FormRestorePass",
  data(){
    return{
      validateformpass: true,
    }
  },

  computed: {
    ...mapState("login/utiles", ["rules"]),
    ...mapState("login",["login", "errorLogin", "overlay" ]),
    ...mapState("login/restorepass", ["restorepass", "loadingoverly"])
  },

  methods: {
    ...mapMutations("login",['switchComponent', 'clearRestoreEmail']),
    ...mapActions("login/restorepass", ["restorepassword"]),
  }
};
</script>
