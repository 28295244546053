import Module from './Module.vue';
import GestionUsersView from "./views/GestionUsersView.vue" 
import CrearCuenta from "./views/RegistrarUsuarioView.vue"
import RolesUsuarioView from "./views/RolesUserView.vue"
const moduleRoute = {

    path: "/gestionusuarios",
    component: Module,
    children: [
        {
            path: "/gestionusuarios", 
            component: GestionUsersView,
            name: "gestionusuarios",
            meta: {
                title: "Gestión de usuarios"
            }
        },
        {
            path: "crearcuenta", 
            component: CrearCuenta,
            name: "crearcuenta",
            meta: {
                title: "Crear cuenta"
            }
        },
        {
            path: "editarcuenta/:id?",
            component: CrearCuenta,
            name: "editarcuenta",
            meta: {
                title: "Editar Cuenta de usuario"
            }
        },
        {
            path: "rolesdeusuario",
            component: RolesUsuarioView,
            name: "rolesdeusuario",
            meta: {
                title: "Roles de usuario"
            }
        },
    ]
}

  
export default router => {
    router.addRoute(moduleRoute);
  };