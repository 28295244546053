import Module from './Module.vue';
import HomeView from './views/HomeView.vue'
const moduleRoute = {

    path: "/",
    component: Module,
    children: [

        {
            path: "/", 
            component: HomeView,
            name: "home",
            meta:{
                requiresAuth: true,
                title: "Página Principal"
            }
          },
    ]
}

  
export default router => {
    router.addRoute(moduleRoute);
  };