<template>
  <v-container fluid>
    <background-login-img />
    <v-row style="height: 100vh" class="bg-residencias">
      <v-col cols="9" class="bg-residencias d-none d-lg-block"> </v-col>
      <v-col
        cols="11"
        lg="3"
        class="d-flex justify-center align-center mx-auto mx-lg-none"
        :class="$vuetify.breakpoint.lgAndUp ? 'white' : ''"
      >
        <component v-bind:is="loginComponents"></component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormLogin from "../components/FormLogin.vue";
import FormRestorePass from "../components/FormRestorePass.vue";
import BackgroundLoginImg from "@/components/backgroundLoginImg.vue";
import { mapMutations, mapState } from 'vuex';
export default {
  name: "Login",

  components: {
    FormLogin,
    FormRestorePass,
    BackgroundLoginImg,
  },

  computed:{
    ...mapState('login', ['loginComponents'])
  }
};
</script>
<style>
.bg-residencias{
    background-image: url('~@/assets/ABQ.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>