import axios from "axios";
import store from "@/store/index";
import swal from "sweetalert";
import router from "@/router";

import metricas from "./metricas"
import registrar from "./registrar"
export default {
  namespaced: true,
  modules:{
    metricas,
    registrar
  },
  state: {
    pagination: {
      page: 1,
      itemsPerPage: 1,
      pageStart: 1,
      pageStop: 1,
      pageCount: 1,
      itemsLength: 1,
    },

    

    findequipo: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
    },

    orderByComumnFor:{
      column: "",
      type: ""
    },

    loadingPDFReporte: false,
    loadingEXCELReporte: false,
    listaEquipos: [],

    filterEquipo: {
      serie: "",
      tipo: "",
      estado: "",
      modelo: "",
      condicion: "",
      proveedor: "",
      ubicacion: "",
      dni: ""
    },

    filterEquipoDefault: {
      serie: "",
      tipo: "",
      estado: "",
      modelo: "",
      condicion: "",
      proveedor: "",
      ubicacion: "",
      dni: ""
    },

    equipo: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
    },
    equipoDefault: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
    },

    loading: false,
    searchserie: "",

    modelosEquipos: []
  }, 
  getters: {},
  mutations: {
    setEquipos(state, payload) {
      state.listaEquipos = payload;
    },

    cleanEquipo(state) {
      state.equipo = null
      state.equipo = Object.assign({}, state.equipoDefault);
      state.idequipo = -1;
      state.searchserie = ""
    },

    cleanFindEquipo(state) {
      state.findequipo = Object.assign({}, state.equipoDefault);
    },

    setSerie(state, payload) {
      state.searchserie = payload;
    },

    setEquipo(state, payload) {
      state.equipo = Object.assign({}, payload);
    },

    setPagination(
      state,
      { itemsPerPage = 50, pageCount, itemsLength, pageStart, pageStop }
    ) {
      state.pagination.itemsPerPage = parseInt(itemsPerPage);
      state.pagination.pageCount = parseInt(pageCount);
      state.pagination.itemsLength = parseInt(itemsLength);
      state.pagination.pageStart = parseInt(pageStart);
      state.pagination.pageStop = parseInt(pageStop);
    },
  },
  actions: {
    
    /*Devuelve el total de equipos y filtra */
    filterGETEquipo({ commit, state }, { page = 1, itemsPerPage = 50 }) {
      store.commit("utiles/resetConfigAxios");
      state.loading = true;
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl +
        "equipo?page=" +
        page +
        "&itemsperpage=" +
        itemsPerPage;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.params = state.filterEquipo;
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          console.log(res)
          commit("setEquipos", res.data.equipos.data);
          state.modelosEquipos = res.data.modelos_equipos
          commit("setPagination", {
            itemsPerPage: res.data.equipos.per_page,
            pageCount: res.data.equipos.last_page,
            itemsLength: res.data.equipos.total,
            pageStart: res.data.equipos.from,
            pageStop: res.data.equipos.to,
          });

          state.loading = false;
        })
        .catch((err) => {
          console.log(err)
          state.loading = false;
        });
    },
    /* RESETEAR FILTROS */
    resetFilters({ commit, dispatch, state }, page) {
      state.filterEquipo = Object.assign({}, state.filterEquipoDefault);
      dispatch("filterGETEquipo", page);
    },

    historialEquipo({ commit, dispatch, state }) {
      state.loading = true
      store.commit("resetConfigAxios");
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "historialequipo";
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.params = { serie: state.searchserie };
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          state.loading = false
          if (res.status == 200) {
            if (res.data.data.length > 0) {
              swal({
                title: "Se encontró el equipo",
                text: "Se muestra todos los detalles",
                icon: "success"
              })
              commit("setEquipo", res.data.data[0]);
            }
          }
        })
        .catch((error) => {
          state.loading = false
          if(error.response.status == 400){
            swal({
              title: "No se encontró el equipo",
              text: "Ingrese otra serie",
              icon: "warning"
            })
          }
        });
    },
    deleteEquipo({ commit, dispatch, state }, idequipo) {
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "equipo/" + idequipo;
      store.state.utiles.configAxios.method = "DELETE";
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      console.log(idequipo)
      swal({
        title: "¿Desea Eliminar este equipo?",
        text: "Recuerda que se borraran todos los registros del equipo: asignaciones y reportes",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) return;
        state.loading = true;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            console.log(res)
            if (res.status == 205) {
              swal({
                title: "No se puede eliminar el equipo!",
                text: "El equipo ya cuenta con una asignación",
                icon: "error",
              });
            }
            if (res.status == 200) {
              dispatch("filterGETEquipo", {page: 1, itemsPerPage: 50});
              swal({
                title: "Se eliminó correctamente!",
                icon: "success",
              });
            }
            if (res.status == 422) {
              swal({
                title: "No se ha encontrado el equipo",
                text: "vuelve a intentar",
                icon: "error"
              });
            }

            state.loading = false;
          })
          .catch((error) => {
            console.log(error);
            state.loading = false;
            swal({
              title: "Ha ocurrido un error",
              icon: "error"
            });
          });
      });
    },

    

    findserieequipo({ commit, state }, serie) {
      store.commit("utiles/resetConfigAxios");
      if (serie == "") {
        swal({ title: "Agregue una serie", icon: "warning" });
        state.loading = false;
        commit("cleanFindEquipo");
        return;
      }

      state.loading = true;
      store.state.utiles.configAxios.url =
      store.state.utiles.apiUrl + "findserieequipo/" + serie;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.data.equipo == null) {
            swal({
              title: "No se encontró el equipo buscado",
              icon: "warning",
            });
            state.loading = false;
            commit("cleanFindEquipo");
            return;
          }
          state.findequipo = Object.assign({}, res.data.equipo);
          state.loading = false;
        })
        .catch((err) => {
          console.log(err);
          state.loading = false;
          commit("cleanFindEquipo");
        });
    },

    /**
     * DESCARGAR REPORTE DE EQUIPOS PDF
     */

    PDFReporteEquipos({commit, dispatch, state}){
      var data = Object.assign({}, state.filterEquipo, state.orderByComumnFor, { user: store.state.user})
   
      state.loadingPDFReporte = true
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "reporteequipos";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.responseType = 'blob'
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
      axios(store.state.utiles.configAxios)
      .then((res) => {
        let blob = new Blob([res.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'reporte-equipos.pdf';
        link.click();
        state.loadingPDFReporte = false
        swal({
          title: "Se generó el archivo",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error)
        state.loadingPDFReporte = false
        swal({
          title: "Ha ocurrido un error",
          text: "Intenta actualizar la página",
          icon: "error"
        })
      });
    },

    /**
     * DESCARGAR REPORTE DE EQUIPOS EXCEL
     */

    downloadReporteEquiposEXCEL({ commit, state }) {
      var data = Object.assign({}, state.filterEquipo, state.orderByComumnFor, { user: store.state.user})
      state.loadingEXCELReporte = true;
      store.commit("utiles/resetConfigAxios");;
      store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "reporteequiposexcel";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.responseType = "blob";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
      axios(store.state.utiles.configAxios)
        .then((response) => {
           // Convierte la respuesta en un objeto Blob
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Genera una URL temporal del archivo Blob
            const url = URL.createObjectURL(blob);
            // Crea un enlace y lo simula como si fuera un clic del usuario
            const a = document.createElement('a');
            a.href = url;
            a.download = 'reporte.xlsx';
            a.click();
          state.loadingEXCELReporte = false;
          swal({
            title: "Se generó el archivo",
            icon: "success",
          });
        })
        .catch((error) => {
          console.error(error);
        // Si la respuesta es un objeto Blob, intenta obtener un mensaje de error a partir de la respuesta
        if (error.response && error.response.data instanceof Blob) {
          error.response.data.text().then(mensaje => {
            console.error('Error en la respuesta:', mensaje);
          });
        }
          state.loadingEXCELReporte = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    },


  },
};
