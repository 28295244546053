<template>
  <div>
    <backgroundSystem />
    <v-row
      class="d-flex justify-center align-center pa-0"
      style="height: 90vh; position: relative;"
    >
      <div class="d-flex flex-wrap justify-center">
        <v-card
          v-if="$can('create', 'Equipos') || $can('read', 'Equipos')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="50" color="white" class="my-2">fa-solid fa-laptop</v-icon>
          </v-avatar>
          <small class="font-weight-black d-block text-center"
            >EQUIPOS TIC <br />
            <span class="font-weight-regular">INVENTARIO</span>
          </small>

          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('create', 'Equipos')"
                :to="{ name: 'registroequipo' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-plus-box</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >NUEVO<br />
                </small>
              </router-link>
            </v-col>

            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('read', 'Equipos')"
                :to="{ name: 'inventariogeneral' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-playlist-check</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >Lista<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          v-if="$can('create', 'Asignaciones') || $can('read', 'Asignaciones') || $can('read', 'AsignacionesRRHH')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
      
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="60" color="white">mdi-clipboard-check</v-icon>
          </v-avatar>
          <small class="font-weight-black d-block text-center"
            >ASIGNACIÓN DE<br />
            <span class="font-weight-regular">EQUIPOS</span>
          </small>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="4" class="pa-0">
              <router-link
                v-if="$can('create', 'Asignaciones')"
                :to="{ name: 'formasignarequipo' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-package-variant</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >NUEVA<br />
                </small>
              </router-link>
            </v-col>
            <v-col cols="4" class="pa-0">
              <router-link
                v-if="$can('create', 'Asignaciones')"
                :to="{ name: 'masivo' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-expand-all</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >MASIVO<br />
                </small>
              </router-link>
            </v-col>
            <v-col cols="4" class="pa-0">
              <router-link
                v-if="$can('read', 'Asignaciones') || $can('read', 'AsignacionesRRHH')"
                :to="{ name: 'asignaciones' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-list-status</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >Lista<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          v-if="$can('create', 'Devoluciones') || $can('read', 'Devoluciones')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="60" color="white">mdi-keyboard-return</v-icon>
          </v-avatar>

          <small class="font-weight-black d-block text-center"
            >DEVOLUCIÓN DE<br />
            <span class="font-weight-regular">EQUIPOS</span>
          </small>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('create', 'Devoluciones')"
                :to="{ name: 'devoluciongeneral' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-hand-coin</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >NUEVA<br />
                </small>
              </router-link>
            </v-col>

            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('read', 'Devoluciones')"
                :to="{ name: 'listadedevoluciones' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-playlist-minus</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >Lista<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>



        <v-card
          v-if="$can('read', 'HistorialE')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
          
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="60" color="white">mdi-timelapse</v-icon>
          </v-avatar>

          <small class="font-weight-black d-block text-center"
            >HISTORIAL DE<br />
            <span class="font-weight-regular">EQUIPOS</span>
          </small>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="6" class="pa-0 mx-auto">
              <router-link
                :to="{ name: 'historialequipo' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-magnify-expand</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >BUSCAR<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>



        <!--
        <v-card
          v-if="$can('read', 'RegistroAsignados')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
          
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="50" color="white">mdi-file-document-multiple-outline</v-icon>
          </v-avatar>

          <small class="font-weight-black d-block text-center"
            >GENERADOR<br />
            <span class="font-weight-regular">DE ACTAS</span>
          </small>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('create', 'Devoluciones')"
                :to="{ name: 'devoluciongeneral' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-account-details-outline</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >ÚNICO<br />
                </small>
              </router-link>
            </v-col>

            <v-col cols="6" class="pa-0">
              <router-link
                v-if="$can('read', 'Devoluciones')"
                :to="{ name: 'listadedevoluciones' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-account-group</v-icon>
                <small class="font-weight-black d-block text-center" style="font-size: 11px;"
                  >VARIOS<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>-->


        
        <!--
        <v-card
          v-if="$can('read', 'RegistroAsignados')"
          rounded="lg"
          outlined
          elevation="0"
          class="cardWidh py-5 px-2 text-center"
        >
          
          <v-avatar color="bgAzulCTM" size="90" class="mb-2">
              <v-icon size="60" color="white">mdi-account-check</v-icon>
          </v-avatar>

          <small class="font-weight-black d-block text-center"
            >REGISTRO DE<br />
            <span class="font-weight-regular">ASIGNADOS</span>
          </small>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="6" class="pa-0 mx-auto">
              <router-link
                :to="{ name: 'busquedaasignados' }"
                style="text-decoration: none; color: inherit"
              >
                <v-icon size="30" color="#0B2A4A">mdi-account-search</v-icon>
                <small class="font-weight-black d-block text-center"
                  >BUSQUEDA<br />
                </small>
              </router-link>
            </v-col>
          </v-row>
        </v-card>--->



      </div>
    </v-row>


  </div>
</template>

<script>
import backgroundSystem from "@/components/backgroundSystem.vue";
import store from '@/store';

export default {
  name: "Home",

  components: {
    backgroundSystem,
  },

  data() {
    return {
      
    };
  },

  methods: {},


  created(){
    console.log(this.$can("create", "usuarios"))
  }


};
</script>

<style scoped>
.cardWidh {
  width: 160px !important;
  margin: 5px;
}

@media (min-width: 1024px) {
  .cardWidh {
    width: 170px !important;
    margin: 10px;
  }
}
</style>
