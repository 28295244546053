import Module from "./Module.vue";

const moduleRoute = {
  path: "/asignaciones",
  component: Module,
  children: [
    {
      path: "",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/ListaAsignaciones.vue"),

      name: "asignaciones",
      meta:{ 
        title: "Lista de Asignaciones",
        requiresAuth: true,
      }
    },
    {
      path: "asignar",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/AsignarEquipo.vue"),

      name: "formasignarequipo",
      meta:{ 
        title: "Nueva Asignación",
        requiresAuth: true,
      }
    },
    {
      path: "asignacion-masiva",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Masivo.vue"),

      name: "masivo",
      meta:{ 
        title: "Asignación Masiva",
        requiresAuth: true,
      }
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
