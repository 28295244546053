<template>
  <div>
    <backgroundSystem />
    <v-row style="position: relative" class="mx-1 mx-md-4 mt-1">
      <v-col cols="12" class="pb-0 d-flex justify-space-between">
        <span class="title-page">
          <b>Historial de Equipos</b>
          <small> | Registro </small>
        </span>

        <div>
          <router-link
            :to="{ name: 'home' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn fab x-small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon>mdi-home-circle</v-icon></v-btn
            >
          </router-link>
        </div>
      </v-col>

      <!--BUSCADOR DE SERIE-->
      <v-col cols="12" class="pb-0">
        <v-card elevation="0" width="400" class="pa-3 rounded-b-0">
          <v-text-field
            outlined
            hide-details="auto"
            background-color="white"
            label="Ingresa serie: "
            dense
            v-model="getSerie"
            @keyup.enter="historialEquipo"
            :loading="loading"
          >
            <template v-slot:append-outer class="mt-0">
              <v-btn
                @click="historialEquipo"
                :loading="loading"
                fab
                x-small
                elevation="0"
                color="bgAzulCTM white--text"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-card>
      </v-col>
      <!--BUSCADOR DE SERIE-->

      <!--INFORMACIÓN DE EQUIPO-->
      <v-col cols="12" md="6" class="pt-0">
        <v-card elevation="0" class="rounded-tl-0">
          <v-card-title class="subtitle-2">
            Información del Equipo:
          </v-card-title>
          <v-card-text class="d-block d-md-flex">
            <v-list style="width: 50%">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-cellphone-link</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.tipo
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >TIPO</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-sticker-check</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.marca
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >MARCA</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-emoticon-outline</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.modelo
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >MODELO</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list style="width: 50%">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM">mdi-numeric</v-icon></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.serie
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >SERIE</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM">mdi-domain</v-icon></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.proveedor
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >PROVEEDOR</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-archive-check</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    equipo.condicion
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >CONDICIÓN</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <!--estado--->
          <v-card-title class="subtitle-2"> Estado actual: </v-card-title>
          <v-card-text v-if="equipo.estadoEquipo">
            <chip-estado :estado="equipo.estadoEquipo" />
            <div
              class="d-block pa-2 grey lighten-3 rounded-lg mt-2"
              v-if="
                equipo.last_observacion != null &&
                equipo.last_observacion.estadoEquipo != 'Revision Pendiente'
              "
            >
              {{ equipo.last_observacion.descripcion }}
            </div>
          </v-card-text>
          <!--estado--->
        </v-card>

        <!--ULTIMAS OBSERVACIÓNES--->
        <v-card elevation="0" class="mt-4 pb-2">
          <v-card-title class="subtitle-2">
            Últimas Observaciones:
          </v-card-title>
          <div
            v-if="equipo.latest_observations == null"
            elevation="0"
            class="text-center"
          >
            Aquí se mostrarán las observciones registradas para este equipo
          </div>
          <div
            v-if="
              equipo.latest_observations != null &&
              equipo.latest_observations.length == 0
            "
            elevation="0"
            class="text-center"
          >
            Este equipo no cuenta con historial de Observaciones registradas
          </div>

          <v-card
            class="yellow lighten-3 mx-2 pa-2 mb-2"
            elevation="0"
            v-for="(lastobser, index) in equipo.latest_observations"
            :key="index"
          >
            <div class="d-flex justify-center align-items-center">
              <div style="width: 10%;">
                <v-avatar class="yellow lighten-2" size="40"
                  ><v-icon class="yellow--text text--darken-4">mdi-alert-rhombus</v-icon></v-avatar
                >
              </div>
              <div style="width: 90%;">
                <div><b>Observación:</b> {{ lastobser.descripcion }}</div>
                <div>
                  <b>Fecha:</b> {{ formatFechaSimple(lastobser.created_at) }}
                </div>
                <div v-if="lastobser.motivo != null">
                  <b>Motivo:</b> {{ lastobser.motivo }}
                </div>
                <div v-if="lastobser.asignacion.estudiante != null">
                  <b>Asignación de :</b>
                  {{ lastobser.asignacion.estudiante.nombre }}
                </div>
                <div v-if="lastobser.asignacion.colaborador != null">
                  <b>Asignación de :</b>
                  {{ lastobser.asignacion.colaborador.nombre }}
                </div>
              </div>
            </div>
          </v-card>
        </v-card>
        <!--ÚLTIMAS OBSERVACIONES-->
      </v-col>

      <v-col cols="12" md="6" class="pt-0">
        <v-card class="pb-3" elevation="0">
          <v-card-title class="subtitle-2">
            Últimas asignaciones:
          </v-card-title>

          <div
            v-for="(asign, index) in equipo.asignaciones"
            :key="index"
            class="ma-4 rounded-b-lg blue lighten-5"
          >
            <div
              class="d-block d-md-flex rounded-lg"
              v-if="asign.estudiante != null"
            >
              <v-list dense style="width: 50%" class="transparent">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-account-arrow-left</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.estudiante.nombre }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >ASIGNADO</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-card-account-details</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.estudiante.dni }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >DNI</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list dense style="width: 50%" class="transparent">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM">mdi-school</v-icon></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.estudiante.admision }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >ADMISIÓN</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-map-marker-left</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.estudiante.sede }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >SEDE</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>

            <!--datos de colaborador-->

            <div
              class="d-block d-md-flex rounded-lg"
              v-if="asign.colaborador != null"
            >
              <v-list dense style="width: 50%" class="transparent">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-account-arrow-left</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.colaborador.nombre }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >ASIGNADO</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-card-account-details</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.colaborador.dni }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >DNI</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list dense style="width: 50%" class="transparent">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-account-tie</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.colaborador.jefatura }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >JEFATURA</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar class="grey lighten-2" size="40"
                      ><v-icon class="clAzulCTM"
                        >mdi-briefcase-outline</v-icon
                      ></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ asign.colaborador.cargo }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption"
                      >CARGO</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <!--datos de colaborador-->
            <!--FECHAS--->
            <v-list class="d-md-flex transparent" dense>
              <v-list-item style="width: 50%">
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-calendar-check</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ formatFechaSimple(asign.fechaasignacion) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >FECHA ASIGNADO</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item style="width: 50%">
                <v-list-item-avatar>
                  <v-avatar class="grey lighten-2" size="40"
                    ><v-icon class="clAzulCTM"
                      >mdi-calendar-end</v-icon
                    ></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ formatFechaSimple(asign.fecha_finalizado)  }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >FECHA FINALIZADO</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!--FECHAS--->

            <div
              :class="
                asign.estado == 'activo' ? 'green accent-3' : 'grey lighten-1'
              "
              class="white--text d-block pa-0 rounded-b-lg text-overline text-center"
            >
              {{ asign.estado }}
            </div>
          </div>

          <div
            v-if="equipo.asignaciones == null"
            elevation="0"
            class="text-center pa-4"
          >
            Aquí se mostrarán las asignaciones asociadas a este equipo
          </div>
          <div
            v-if="
              equipo.asignaciones != null && equipo.asignaciones.length == 0
            "
            elevation="0"
            class="text-center pa-4"
          >
            Este equipo no cuenta con asignaciones recientes
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import backgroundSystem from "@/components/backgroundSystem.vue";
import chipEstado from "@/components/equipo/ChipEstadoComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";
import Filters from '@/Filters';
export default {
  mixins:[Filters],
  name: "historialEquipo",
  components: {
    backgroundSystem,
    chipEstado,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("equipos", ["searchserie", "equipo", "loading"]),
    getSerie: {
      get() {
        return this.searchserie;
      },
      set(val) {
        this.setSerie(val);
      },
    },
  },
  methods: {
    ...mapActions("equipos", ["historialEquipo"]),
    ...mapMutations("equipos", ["setSerie", "cleanEquipo"]),
  },

  created() {
    if (!this.$can("read", "HistorialE")) {
      router.push({ name: "home" });
    }
  },
  mounted() {
    this.cleanEquipo();
    console.log(this.equipo);
  },
};
</script>