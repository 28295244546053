<template>
  <v-navigation-drawer app 
  v-model="nav.drawer"
  :permanent="nav.drawer" 
  color="bgAzulCTM" width="60">
    <div style="position: absolute; top: 0px; width: 100%;" class="my-1">
      <v-img width="50" :src="require('@/assets/logo-300x300.png')" class="d-block mx-auto"></v-img>
    </div>
    <div
      class="d-flex flex-column align-center justify-center"
      style="height: 100vh"
    >

    <router-link :to="{ name: 'gestionusuarios' }" style="text-decoration: none; color: inherit">
     <v-card elevation="0" :color="nameroute=='gestionusuarios'?'bgAzulCTMOpacity':'transparent'" width="70">
        <v-card-text class="py-2 px-1 text-center">
          <v-icon class="white--text" size="30">mdi-account-group</v-icon>
          <div class="title-menu font-weight-bold white--text">USUARIOS</div>
        </v-card-text>
      </v-card>
    </router-link>
  
    <router-link :to="{ name: 'rolesdeusuario' }" style="text-decoration: none; color: inherit">
      <v-card elevation="0" :color="nameroute=='rolesdeusuario'?'bgAzulCTMOpacity':'transparent'" width="70">
        <v-card-text class="py-2 px-1 text-center">
          <v-icon class="white--text" size="30">mdi-account-tie</v-icon>
          <div class="title-menu font-weight-bold white--text">ROLES</div>
        </v-card-text>
      </v-card>
    </router-link>

    </div>
  </v-navigation-drawer>
</template>
<script>

import { mapMutations, mapState } from 'vuex'
export default {
  name: "Navigation-Drawer",
  data(){
    return{
      nameroute: ""
    }
  },
  computed:{
    isNotMovil(){
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg
    },
    ...mapState(['nav'])
  },
  watch: {
  '$route' (to, from) {
    if(to.path != from.path) { // <- you may need to change this according to your needs!
      this.nameroute = to.name
    }
  }
},
  methods:{
  },
  mounted(){
    console.log(this.$route)
  }
}
</script>


<style scoped>
.title-menu {
  font-size: 10px;
}
</style>
