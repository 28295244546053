import Module from './Module.vue';
const moduleRoute = {

    path: "/equipos",
    component: Module,
    children: [

        {
            path: "registroequipo/:id?",
            name: "registroequipo",
            component: () =>
              import(/* webpackChunkName: "about" */ "./views/RegistroEquipoView"),
            meta: {
              requiresAuth: true,
              title: "Registrar un Equipo",
            },
          },
          {
            path: "editarequipo/:id?",
            name: "editarequipo",
            component: () =>
              import(/* webpackChunkName: "about" */ "./views/RegistroEquipoView"),
            meta: {
              requiresAuth: true,
              title: "Editar un Equipo",
            },
          },
          {
            path: "",
            name: "inventariogeneral",
            component: () =>
              import(/* webpackChunkName: "about" */ "./views/ListaEquiposTic"),
            meta: {
              requiresAuth: true,
              title: "Lista de Equipos (Inventario General)",
            },
          },
    ]
}

  
export default router => {
    router.addRoute(moduleRoute);
  };