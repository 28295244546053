import store from "@/store";

import router from "@/router";
import axios from "axios";


import utiles from "@/utiles";

export default{
    namespaced: true,
    modules:{
      utiles
    },
    state:{
        updatepassword: {
            currentpass: "",
            newpassword: "",
          },
      
          updatepasswordDefault: {
            currentpass: "",
            newpassword: "",
          },
      
          newpassword: {
            password: "",
            password_confirmation: "",
          },
      
          loadingpass: false,
          loadingoverly:false,
          restorepass: {
            email: "",
          },
      
          updatepass: {
            newpassword: "",
            password: "",
          },
      
          validatetokenpass: false,
          loading:false
    },

    mutations:{
      clearUpdatePassword(state) {
        state.updatepassword = Object.assign({}, state.updatepasswordDefault);
      },
    },
    actions:{
         /**
     * Restablecer contraseña
     *
     */

    restorepassword({ commit, state }) {
        state.loadingoverly = true;
        store.commit("utiles/resetConfigAxios");
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "sendtokenrestore";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = state.restorepass;
        console.log(state.restorepass);
        axios(store.state.utiles.configAxios)
          .then((res) => {
            swal({
              title: "Acabamos de enviarte un correo!",
              text: "Revisa tu bandeja de entrada y sigue los pasos",
              icon: "success",
            });
            state.loadingoverly = false;
            state.restorepass.email = "";
          })
          .catch((error) => {
            swal({
              title: "Correo no registrado",
              text: "Verifica que esté correctamente escrito",
              icon: "error",
            });
            state.loadingoverly = false;
          });
      },
  
      /**
       * validar token de cambio de contraseña
       */
  
      valitokenpass({ commit, state }, data) {
        state.loading = true;
        store.commit("utiles/resetConfigAxios");
        store.state.utiles.configAxios.url = state.utiles.apiUrl + "valitokenpass";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = data;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            state.validatetokenpass = true;
            state.loading = false;
          })
          .catch((error) => {
            console.log(error)
            state.validatetokenpass = false;
            state.loading = false;
          });
      },


      updatepassworduser({ commit, state }) {
        state.loading = true;
        store.commit("utiles/resetConfigAxios");
        var data = Object.assign({}, state.updatepassword, store.state.user);
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "updatepassword";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        store.state.utiles.configAxios.data = data;
  
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 200) {
              swal({
                title: "Se cambió la contraseña",
                icon: "success",
              });
  
              state.loading = false;
              commit("clearUpdatePassword");
            }
          })
          .catch((error) => {
            swal({
              title: "Contraseña actual no válida",
              icon: "error",
            });
  
            state.loading = false;
          });
      },
  
      /**
       * Cambiar contraseña desde token
       */
  
      updatepasstoken({ commit, state }, email) {
        state.loadingpass = true;
        var data = Object.assign({}, state.newpassword, { email: email });
        store.commit("utiles/resetConfigAxios");
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "updatepasstoken";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = data;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            state.loadingpass = false;
            swal({
              title: "Contraseña actualizada!",
              text: "Vuelva a iniciar sesión con la nueva contraseña",
              icon: "success",
            });
            router.push({ name: "login" });
          })
          .catch((error) => {
            state.loadingpass = false;
            swal({
              title: "Ha ocurrido un error!",
              text: "Comuniquese con el administrador de la plataforma",
              icon: "error",
            });
          });
      },
  
    }
} 