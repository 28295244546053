import Module from './Module.vue';
const moduleRoute = {

    path: "/devoluciones",
    component: Module,
    children: [

        {
          path: "", 
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/DevolucionesView.vue"),
          name: "listadedevoluciones",
          meta: {
            requiresAuth: true,
            title: "Lista de devoluciones de Equipos",
          },
        },
        {
            path: "/nueva", 
            component: () =>
            import(/* webpackChunkName: "about" */ "./views/DevolverEquipo.vue"),
            name: "devoluciongeneral",
            meta: {
                requiresAuth: true,
                title: "Devolver Equipo",
              },
          },

    ]
}

  
export default router => {
    router.addRoute(moduleRoute);
  };