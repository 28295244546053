import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import router from "@/router";
export default{

    namespaced: true,

    state:{
        registerUser: {
            iduser: -1,
            avatar: "",
            name: "",
            email: "",
            dni: "",
            password_confirmation: "",
            role_id: null,
            cargo: "",
          },
      
          registerUserDefault: {
            iduser: -1,
            avatar: "",
            name: "",
            email: "",
            dni: "",
            password_confirmation: "",
            role_id: null,
            cargo: "",
          },
    },

    mutations:{
      clearRegisterUser(state) {
        state.registerUser = Object.assign({}, state.registerUserDefault);
      },
  
    },

    actions:{
      createUser({ commit, dispatch, state }) {
        state.loadingEdit = true;
        store.commit("utiles/resetConfigAxios");
        state.registerUser.password = state.registerUser.password_confirmation;
        var msgswal = "Usuario Creado";
        store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "user";
        if (state.registerUser.iduser != -1) {
          store.state.utiles.configAxios.url = store.state.utiles.apiUrl + "updateuser";
          msgswal = "Se actualizó el usuario!";
        }
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = state.registerUser;
        store.state.utiles.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 200) {
              swal({
                title: msgswal,
                icon: "success",
              });
              setTimeout(() => {
                router.push({ name: "gestionusuarios" });
              }, 300);
            }
            state.loading = false;
            
            
          })
          .catch((error) => {
            console.log(error)
            state.loading = false;
          });
      },
    }
}
