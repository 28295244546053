import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
export default {
  namespaced: true,
  state: {
    findcolaborador: {},
  },

  actions: {
    findDniColaborador({ commit, state }, dni) {
      store.state.asignaciones.loadingfindni = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        "https://ctm-colaboradores.cetemin.edu.pe/api/v1/colaborador/" + dni;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer 3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a";
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status === 200) {
          
            store.state.asignaciones.asignacion.nombre = res.data.nombrecompleto

            if(res.data.area == "ADM. Y FINANZAS"){
              store.state.asignaciones.asignacion.jefatura = "ADMINISTRACIÓN Y FINANZAS"
            }else{
              store.state.asignaciones.asignacion.jefatura = res.data.area
            }

            store.state.asignaciones.asignacion.cargo = res.data.cargo
          }

          store.state.asignaciones.loadingfindni = false;
        })
        .catch((error) => {
          console.log(error);
          store.state.asignaciones.loadingfindni = false;
        });
    },

    /***
     * Busqueda API RENIEC
     */

    getApiReniecAxios({ commit, state }, dni) {
      store.state.asignaciones.loadingfindni = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url = "https://api.apirest.pe/api/getDniPremium";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = { dni: dni };
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhhYjYyMWVkNDBmMmVhMzlmMDg3M2Y2ZGQzOWIwZjQzMGFmZGE1ODBkY2JiYjk4ZDYyM2ZmOGM2NzJiNzQxN2JiZTZjNDQwMDc0MjZhYzZkIn0.eyJhdWQiOiIxIiwianRpIjoiOGFiNjIxZWQ0MGYyZWEzOWYwODczZjZkZDM5YjBmNDMwYWZkYTU4MGRjYmJiOThkNjIzZmY4YzY3MmI3NDE3YmJlNmM0NDAwNzQyNmFjNmQiLCJpYXQiOjE1NjY0MjMxODYsIm5iZiI6MTU2NjQyMzE4NiwiZXhwIjoxODgyMDQyMzg2LCJzdWIiOiIxNTUiLCJzY29wZXMiOlsiKiJdfQ.gMN_mZ7o3n_ODVX0h5kIU-xmN6HETZU98zXE2AZHywtb0Q7A35DfssNcr34wIN_TaPWHhXWn5MmYnTEhk20pGQb-1_ebGr2uZRdyiHlniY7rky7ZWlvsYDfp8p1gxh5gfEZbgTjQCZl0gJP6bh1yURzJSm3hcSYLgjR7oz6uYokV8FFSHIhrxLbrWBGapNVdqKRBELJ1clSNDRKH2CT2criscOtkXQEbZSY68CP4eTIoVk0C4-ghx1TlxSp4wMOV6_YgRc8JGMXSEpX3r9gZ1AcER3BTyfzRz0JlpXiE_F0C0NtQH8dFf1uYQv1d8S3wYKjBWAmn4QMGdsIphHcswfEpM9_o8gwBIdEzYm3vv5NJNAp79OGq9kHT7gtwiEV9cqdi5fgZ-nVvXmUN7wwi1ef87OKgP8j3K6AePB7R5W9ElqLwHy0O2j8urz992ZHFynMwLVd02L_ElIM9xeBDjblB-awg-RN9Krw29uXgYcsUdyOkmrmLON2Lg4-5De7-5g2cR-vyKiLCzRILvJBs-BmcrVo-UHkk99bIL3wUT6C9akMZ7crqRzbYBil5-Cln6WS6_9pMww0in6YhRm_jv-QY23xH5WZVWE3RwKCAE-zu4EeRWP4dnL2RbhH_SJO19EZVUSVj1frf9T7u6rGQUYegK7AxyApDGkqgSpFHB6Y";
      axios(store.state.utiles.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            store.state.asignaciones.asignacion.nombre = resp.data.result.NombreCompleto;
            store.state.asignaciones.loadingfindni = false;
            store.commit('asignaciones/setAlertAsignacion', {type: 'success', message: "DNI validado! - complete los datos faltantes"} )
          }
        })
        .catch((error) => {
          console.log(error);
          store.state.asignaciones.loadingfindni = false;
          store.commit('asignaciones/setAlertAsignacion', {type: 'error', message: "DNI no encontrado! - complete los datos faltantes"} )
        });
    },
  },
};
