import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { abilitiesPlugin } from '@casl/vue';

Vue.config.productionTip = false



Vue.use(abilitiesPlugin, store.getters.ability, {
  useGlobalProperties: true
})




/*Modules*/
import LoginModule from "./modules/Login"
import HomeModule from "./modules/Home"
import UsersModule from "./modules/Users"
import EquiposModule from "./modules/Equipos"
import AsignacionesModule from "./modules/Asignaciones"
import DevolucionesModule from './modules/Devoluciones'
import HistorialEquipos from './modules/HistorialEquipos'
/*End Modules*/

import { registerModules } from "./register-modules";
registerModules({
    login: LoginModule,
    home: HomeModule,
    users: UsersModule,
    equipos: EquiposModule,
    asignaciones: AsignacionesModule,
    devoluciones: DevolucionesModule,
    historialequipos: HistorialEquipos
  })
 

new Vue({
  router, 
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
