import Module from './Module.vue';
import HistorialEquipos from './views/HistorialEquipos.vue';
const moduleRoute = {

    path: "/historial-equipos",
    component: Module,
    children: [

        {
            path: "", 
            component: HistorialEquipos,
            name: "historialequipo",
            meta:{
                title: "Historial de Equipos"
            }
          },
    ]
}
 
  
export default router => {
    router.addRoute(moduleRoute);
  };