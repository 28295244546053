import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import router from "@/router";
import colaborador from "./colaborador";
import estudiante from "./estudiante";
import masivo from "./masivo";

export default {
  namespaced: true,
  modules: {
    colaborador,
    estudiante,
    masivo
  },
  state: {
    urlPreviewFile: "",
    typeFilePreview: null,
    errorFileUpload: true,
    modaluploadfile: false,
    overlayloading: false,
    loadingUpdate: false,
    defaultItemsPerPage: 60,
    asignacion: {
      tipoasignado: "estudiante",
      fechaasignacion: "",
      fechaactual: true,
      dni: "",
      nombre: "",
      telefono: "",
      direccion: "",
      carrera: "",
      admision: "",
      sede: "",
      condicion: "",
      proyecto: "",
      jefatura: "",
      cargo: "",
      comentario: "",
      actaEntrega: "",
      evidenciadeentrega: "",
      lugardeentrega: "",
      equiposAsignados: [],
      estado: "",
      user_id: "", 
      tipodeentrega: "beca",
      fechadevolucion: ""
    },

    asignacionDefault: {
      tipoasignado: "estudiante",
      fechaasignacion: "",
      fechaactual: true,
      dni: "",
      nombre: "",
      telefono: "",
      direccion: "",
      carrera: "",
      admision: "",
      sede: "",
      condicion: "",
      proyecto: "",
      jefatura: "",
      cargo: "",
      comentario: "",
      actaEntrega: "",
      evidenciadeentrega: "",
      lugardeentrega: "",
      equiposAsignados: [],
      estado: "",
      user_id: "",
      tipodeentrega:"beca",
      fechadevolucion: ""
    },
    asignaciones: [],
    loading: false,
    loadingEquipos: false,
    filters: {
      tipoasignado: "estudiante",
      dni: "",
      nombre: "",
      sede: "",
      carrera: "",
      admision: "",
      estado: ["activo"],
      proyecto: "",
      serie: "",
      fecha: "",
      jefatura: "",
      cargo: "",
      usuario: "",
      fechaasignacion: "",
      condicion: "",
      tipodeentrega: null
    },
    filtersDefault: {
      tipoasignado: "estudiante",
      dni: "",
      nombre: "",
      sede: "",
      carrera: "",
      admision: "",
      estado: ["activo"],
      proyecto: "",
      serie: "",
      fecha: "",
      jefatura: "",
      cargo: "",
      usuario: "",
      fechaasignacion: "",
      condicion: "",
      tipodeentrega: null
    },

    listaEquiposAsignados: [],

    dialogAsignar: false,

    infoAgregar: {
      motivo: "",
      comentario: "",
    },

    infoAgregarDefault: {
      motivo: "",
      comentario: "",
    },

    idasignacionselected: -1,

    pagination: {
      itemsPerPage: 20,
      itemsLength: 0,
      page: 1,
    },

    loadingPDF: false,
    orderByComumnFor: {
      column: "",
      type: "",
    },
    loadingPDFReporte: false,
    loadingExcelReporte: false,
    loadingsaveasign: false,
    loadingfindni: false,
    loadingComment: false,
    findequipo: {
      idequipo: -1,
      tipo: "",
      serie: "",
      marca: "",
      modelo: "",
      estadoEquipo: "",
      precio: 0,
      condicionEquipo: "",
      proveedor: "",
      ubicacion: "",
      descripcion: "",
    },

    alertBusquedaDNI: {
      type: "",
      message: "",
    },
  },

  mutations: {
    setDefaultIPP(state, payload) {
      state.defaultItemsPerPage = payload;
    },
    setAsignaciones(state, payload) {
      state.asignaciones = payload;
    },

    resetAllAsignaciones(state) {
      state.asignaciones = [];
    },

    setDataAsignado(state, payload) {
      state.asignacion = Object.assign(state.asignacion, payload);
      console.log(state.asignacion);
    },

    resetAll(state) {
      state.asignacion = Object.assign({}, state.asignacionDefault);
      state.asignacion.equiposAsignados = [];
      state.urlPreviewFile = "";
      state.typeFilePreview = null;
    },

    resetFileUpload(state) {
      state.urlPreviewFile = "";
      state.typeFilePreview = null;
      state.errorFileUpload = false;
    },

    asignarEquipo(state, equipo) {
      if (
        state.asignacion.equiposAsignados.find((eq) => eq.serie == equipo.serie)
      ) {
        swal({
          title: "Ya está asignado este equipo",
          text: "Serie: " + equipo.serie,
          icon: "error",
        });
        return;
      }
      state.asignacion.equiposAsignados.push(equipo);
    },

    setModalUpload(state, payload) {
      state.modaluploadfile = payload;
    },

    quitarEquipo(state, equipo) {
      swal({
        title: "¿Desea eliminar este equipo?",
        text: "Podrá asignarlo nuevamente, si desea",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) return;
        let indexEq = state.asignacion.equiposAsignados.indexOf(equipo);
        state.asignacion.equiposAsignados.splice(indexEq, 1);
      });
    },

    setEditAsignacion(state, payload) {
      payload.tipoasignado = state.filters.tipoasignado;
      state.asignacion = Object.assign(
        {},
        {
          tipoasignado: state.filters.tipoasignado,
          idasignacion: payload.idasignacion,
          estudiante_id:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.idestudiante
              : null,
          colaborador_id:
            payload.tipoasignado === "colaborador"
              ? payload.colaborador.idcolaborador
              : null,
          nombre:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.nombre
              : payload.colaborador.nombre,
          dni:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.dni
              : payload.colaborador.dni,
          telefono:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.telefono
              : payload.colaborador.telefono,
          direccion:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.direccion
              : payload.colaborador.direccion,
          carrera:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.carrera
              : null,
          admision:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.admision
              : null,
          sede:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.sede
              : payload.colaborador.sede,
          condicion:
            payload.tipoasignado === "estudiante"
              ? payload.estudiante.condicion
              : null,
          jefatura:
            payload.tipoasignado === "colaborador"
              ? payload.colaborador.jefatura
              : null,
          cargo:
            payload.tipoasignado === "colaborador"
              ? payload.colaborador.cargo
              : null,
          proyecto: payload.proyecto,
          lugardeentrega: payload.lugardeentrega ,
          fechaasignacion: moment(payload.fechaasignacion).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          tipodeentrega: payload.tipodeentrega == 'alquiler' ? 'alquiler' : 'beca',
          fechadevolucion: payload.fechadevolucion?  moment(payload.fechadevolucion).format(
            "YYYY-MM-DD HH:mm:ss"
          ): null,
        }
      );
    },

    addEvidencia(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });
        state.asignacion.evidenciadeentrega = null;
        state.typeFilePreview = null;
        state.urlPreviewFile = null;
        state.errorFileUpload = true;
        return;
      }
      state.errorFileUpload = event ? false : true;
      state.typeFilePreview = event ? event.type : null;
      state.asignacion.evidenciadeentrega = event;
      state.urlPreviewFile = event ? URL.createObjectURL(event) : null;
    },

    confirmAsignacionNueva(state, equipo) {
      state.dialogAsignar = true;
      state.infoAgregar = Object.assign(state.infoAgregar, {
        equipo_id: equipo.idequipo,
        asignacion_id: state.idasignacionselected,
      });
      console.log(state.infoAgregar);
    },

    closeDialogNuevaAsignacion(state) {
      state.dialogAsignar = false;
      state.infoAgregar = Object.assign({}, state.infoAgregarDefault);
    },

    clearFilters(state, roleRRHH) {
      state.filters = Object.assign({}, state.filtersDefault);
      if(roleRRHH)
      {
        state.filters.tipoasignado = 'colaborador'
      }

      console.log(roleRRHH)
    },

    setAlertAsignacion(state, payload) {
      state.alertBusquedaDNI.type = payload.type;
      state.alertBusquedaDNI.message = payload.message;
    },

    resetAlertAsignacion(state) {
      state.alertBusquedaDNI = Object.assign({}, { type: "", message: "" });
    },
  },

  actions: {
    saveAsignacion({ commit, state }) {
      store.commit("utiles/resetConfigAxios");

      state.asignacion.fechaasignacion = state.asignacion.fechaactual
        ? moment().format("YYYY-MM-DD HH:mm:ss")
        : moment(state.asignacion.fechaasignacion).format(
            "YYYY-MM-DD HH:mm:ss"
          );

      state.asignacion.estado = "activo";
      state.asignacion.user_id = store.state.user.iduser;
      let data = new FormData();
      for (let key in state.asignacion) {
        if (key == "equiposAsignados") {
          data.append(
            "equiposAsignados",
            JSON.stringify(state.asignacion.equiposAsignados)
          );
          continue;
        }
        data.append(key, state.asignacion[key]);
      }
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "asignacion";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Desea guardar la Asignación?",
        text: "Recuerde revisar todo los datos ya que no podrá modificarlos",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) {
          return;
        }
        state.loadingsaveasign = true;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 205) {
              swal({
                title: "Ya existe una asignación activa con este DNI",
                text: "DNI: " + state.asignacion.dni,
                icon: "error",
              });
              state.loadingsaveasign = false;
              return;
            }
            if (res.status == 206) {
              swal({ title: "Debe asignar al menos un equipo", icon: "error" });
              state.loadingsaveasign = false;
              return;
            }

            if (res.status == 200) {
              swal({
                title: "Asignación creada correctamente",
                icon: "success",
              });
              state.asignacion = Object.assign({}, state.asignacionDefault);
              setTimeout(() => {
                router.push({ name: "asignaciones" });
              }, 300);
            }
            state.loadingsaveasign = false;
          })
          .catch(function (error) {
            if (error.response.status) {
              swal({
                title: "Ocurrió un error",
                text: "Verifique y vuelva a intentar",
                icon: "error",
              });
            }
            console.log(error);
            state.loadingsaveasign = false;
          });
      });
    },

    axiosAsignaciones({ commit, state }, { page = 1, itemsPerPage }) {
      store.commit("utiles/resetConfigAxios");
      commit("setAsignaciones", []);
      state.loading = true;
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl +
        "asignacion?page=" +
        page +
        "&itemsperpage=" +
        itemsPerPage;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.params = state.filters;
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          console.log(res);
          commit("setAsignaciones", res.data.data);
          state.loading = false;
          state.pagination.itemsPerPage = parseInt(res.data.meta.per_page);
          state.pagination.itemsLength = parseInt(res.data.meta.total);
          console.log(res);
        })
        .catch((error) => {
          state.loading = false;
          console.log(error);
        });
    },

    resetFilters({ dispatch, state }, { page = 1, itemsPerPage }) {
      state.filters = Object.assign({}, state.filtersDefault);
      dispatch("axiosAsignaciones", {
        page: 1,
        itemsPerPage: state.defaultItemsPerPage,
      });
    },

    /**
     * UPDATE DATOS DE ASIGNACIÓN Y ASIGNADO
     */

    updateasignacion({ commit, dispatch, state }) {
      swal({
        title: "¿Desea guardar los cambios?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) return;

        console.log(state.asignacion);
        state.loadingUpdate = true;
        store.commit("utiles/resetConfigAxios");
        store.state.utiles.configAxios.url =
          store.state.utiles.apiUrl + "updateasignacion";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = state.asignacion;
        store.state.utiles.configAxios.params = state.filters;
        store.state.utiles.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        axios(store.state.utiles.configAxios)
          .then((res) => {
            state.loadingUpdate = false;
            dispatch("axiosAsignaciones", {
              page: 1,
              itemsPerPage: state.defaultItemsPerPage,
            });
            swal({
              title: "Se actualizó los datos",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 500) {
              state.loadingUpdate = false;
              swal({
                title: "Ha ocurrido un error",
                icon: "error",
              });
            }
          });
      });
    },

    /**
     * Update comentario
     */

    saveComent(
      { commit, dispatch, state },
      { comentario, idasignacion, setNull }
    ) {
      swal({
        title: setNull
          ? "¿Desea eliminar el comentario?"
          : "¿Desea guardar el comentario?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (!confirm) return;

        state.loadingComment = true;
        store.commit("utiles/resetConfigAxios");
        store.state.utiles.configAxios.url =
          store.state.utiles.apiUrl + "savecomentario";
        store.state.utiles.configAxios.method = "POST";
        store.state.utiles.configAxios.data = {
          comentario: comentario,
          idasignacion: idasignacion,
        };

        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status === 200) {
              state.loadingComment = false;
              dispatch("axiosAsignaciones", {
                page: 1,
                itemsPerPage: state.defaultItemsPerPage,
              });

              swal({
                title: setNull
                  ? "Se elimino el comentario"
                  : "Comentario guardado!",
                icon: "success",
              });
            }
          })
          .catch((error) => {
            state.loadingComment = false;
            swal({
              title: "A ocurrido un error",
              icon: "error",
            });
          });
      });
    },

    /**
     * Meetodo para agregar una evidencia de entrega (acta firmada foto o pdf)
     */

    addEvidenciaEntrega({ commit, dispatch, state }, item) {
      state.overlayloading = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "addevidencia";
      store.state.utiles.configAxios.method = "POST";

      var data = new FormData();
      data.append("idasignacion", item.idasignacion);
      data.append("evidenciadeentrega", state.asignacion.evidenciadeentrega);
      store.state.utiles.configAxios.data = data;

      store.state.utiles.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };

      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 200) {
            dispatch("axiosAsignaciones", {
              page: 1,
              itemsPerPage: state.defaultItemsPerPage,
            });
            commit("resetFileUpload");
            swal({
              title: "Se guardó el archivo correctamente",
              icon: "success",
            });
          }
          state.overlayloading = false;
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "A ocurrido un Error",
            text: "Vuelve a intentar",
            icon: "error",
          });
          state.overlayloading = false;
        });
    },

    /**
     *
     * Metodo para quitar equipo asignado
     * Condiciones
     * Solo se podrá quitar si el equipo no cuenta con observaciones activas
     */

    quitarEquipoAsignado({ commit, dispatch, state }, infoequipo) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "quitarequipo";
      store.state.utiles.configAxios.method = "DELETE";
      store.state.utiles.configAxios.data = infoequipo;
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
      axios(store.state.utiles.configAxios)
        .then((res) => {
          if (res.status == 205) {
            swal({
              title: "Advertencia",
              text: "Este equipo no se puede eliminar ya que cuenta con una observación",
              icon: "error",
            });

            return;
          }

          swal({
            title: "Se ha elimiando el equipo",
            icon: "success",
          });
          dispatch("getEquiposAsignados", infoequipo.idasignacion);

          setTimeout(() => {
            dispatch("axiosAsignaciones", {
              page: 1,
              itemsPerPage: state.defaultItemsPerPage,
            });
          }, 300);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    agregarEquipoAsignado({ commit, dispatch, state }) {
      store.commit("utiles/resetConfigAxios");
      state.loadingEquipos = true;
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "agregarequipo";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = state.infoAgregar;
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
      axios(store.state.utiles.configAxios)
        .then((res) => {
          state.loadingEquipos = false;
          if (res.status == 205) {
            swal({
              title: "Esta asignación ya finalizó",
              text: "No puede agregar más equipos",
              icon: "error",
            });
            return;
          }

          if (res.status == 200) {
            commit("closeDialogNuevaAsignacion");
            swal({
              title: "Asignación agregada con exito",
              icon: "success",
            });

            dispatch("getEquiposAsignados", state.idasignacionselected);

            setTimeout(() => {
              dispatch("axiosAsignaciones", {
                page: 1,
                itemsPerPage: state.defaultItemsPerPage,
              });
            }, 3000);
          }
        })
        .catch(function (error) {});
    },

    getEquiposAsignados({ commit, state }, idasignacion) {
      store.commit("utiles/resetConfigAxios");
      state.listaEquiposAsignados = [];
      state.loadingEquipos = true;
      state.idasignacionselected = idasignacion;
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "equiposasignados/" + idasignacion;
      store.state.utiles.configAxios.method = "GET";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(store.state.utiles.configAxios)
        .then((res) => {
          state.listaEquiposAsignados = res.data.data;
          state.loadingEquipos = false;
        })
        .catch((error) => {
          state.loadingEquipos = false;
        });
    },

    destoyAsignacion({ commit, dispatch, state }, idasignacion) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "asignacion/" + idasignacion;
      store.state.utiles.configAxios.method = "DELETE";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Desea Eliminar la asignación?",
        text: "Perderá información valiosa",
        icon: "warning",
        dangerMode: true,
        buttons: true,
      }).then((confirm) => {
        if (!confirm) {
          return;
        }

        state.loading = true;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 205) {
              swal({
                title: "No se puede eliminar la asignación",
                text: "Tiene equipos con observaciones pendientes",
                icon: "warning",
              });
              state.loading = false;
              return;
            }

            if (res.status == 200) {
              swal({
                title: "Se eliminó la asignación",
                icon: "success",
              });
              dispatch("axiosAsignaciones", {
                page: 1,
                itemsPerPage: state.defaultItemsPerPage,
              });
              //state.loading = false;
            }
          })
          .catch((error) => {
            swal({
              title: "Ha ocurrido un error",
              icon: "error",
            });
          });
      });
    },

    /**
     * Finalizar Asignación
     */

    finalizarAsignacion({ commit, dispatch, state }, infoasignacion) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "finalizarasignacion";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = infoasignacion;
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿DESEA FINALIZAR ESTA ASIGNACIÓN?",
        text: "Solo se podrá finalizar cuando no tenga ningun equipo con observación pendiente",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 205) {
              swal({
                title: "No se pudo finalizar la asignación",
                text: "La asignación tiene equipos con observaciónes",
                icon: "error",
              });

              return;
            }

            if (res.status == 200) {
              swal({
                title: "Finalizó la Asignación",
                icon: "success",
              });

              dispatch("axiosAsignaciones", {
                page: 1,
                itemsPerPage: state.defaultItemsPerPage,
              });
            }
          })
          .catch((error) => {
            swal({
              title: "Ha ocurrido un error",
              icon: "error",
            });
          });
      });
    },

    /**
     * Activar Asignación
     */

    activarAsignacion({ commit, dispatch, state }, infoasignacion) {
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "activarasignacion";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = infoasignacion;

      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿DESEA ACTIVAR ESTA ASIGNACIÓN?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }
        axios(store.state.utiles.configAxios)
          .then((res) => {
            if (res.status == 205) {
              swal({
                title: "La asignación ya se encuentra activa",
                text: "No es necesario volver a reactivarla",
                icon: "info",
              });

              return;
            }
            if (res.status == 200) {
              swal({
                title: "Se activó la Asignación",
                icon: "success",
              });
              dispatch("axiosAsignaciones", {
                page: 1,
                itemsPerPage: state.defaultItemsPerPage,
              });
            }
          })
          .catch((error) => {});
      });
    },

    downloadPdf({ commit, state }, data) {
      state.loadingPDF = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.apiUrl + "actaentregaestudiante";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = { data: data };
      store.state.utiles.configAxios.responseType = "blob";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      axios(store.state.utiles.configAxios)
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var nombre =
            data.estudiante != null
              ? data.estudiante.nombre
              : data.colaborador.nombre;
          link.download =
            "acta-de-entrega-" + nombre.replace(" ", "-") + ".pdf";
          link.click();
          state.loadingPDF = false;
          swal({
            title: "Se generó el archivo",
            icon: "success",
          });
        })
        .catch((error) => {
          state.loadingPDF = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    },

    downloadReporte({ commit, state }) {
      var data = Object.assign({}, state.filters, state.orderByComumnFor, {
        user: store.state.user,
      });
      console.log(data);
      state.loadingPDFReporte = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "reporteasignaciones";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.responseType = "blob";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      axios(store.state.utiles.configAxios)
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "reporte-asignaciones.pdf";
          link.click();
          state.loadingPDFReporte = false;
          swal({
            title: "Se generó el archivo",
            icon: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          state.loadingPDFReporte = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    },

    /**
     *
     * Reporte en EXCEL
     */
    downloadReporteEXCEL({ commit, state }) {
      var data = Object.assign({}, state.filters, state.orderByComumnFor, {
        user: store.state.user,
      });
      state.loadingExcelReporte = true;
      store.commit("utiles/resetConfigAxios");
      store.state.utiles.configAxios.url =
        store.state.utiles.apiUrl + "reporteasignacionesexcel";
      store.state.utiles.configAxios.method = "POST";
      store.state.utiles.configAxios.data = data;
      store.state.utiles.configAxios.responseType = "blob";
      store.state.utiles.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      axios(store.state.utiles.configAxios)
        .then((response) => {
          // Convierte la respuesta en un objeto Blob
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte.xlsx";
          a.click();
          state.loadingExcelReporte = false;
          swal({
            title: "Se generó el archivo",
            icon: "success",
          });
        })
        .catch((error) => {
          console.error(error);
          // Si la respuesta es un objeto Blob, intenta obtener un mensaje de error a partir de la respuesta
          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((mensaje) => {
              console.error("Error en la respuesta:", mensaje);
            });
          }
          state.loadingExcelReporte = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    },

    /* Eliminar evidencia de entrega */

    deleteEvidencia({ commit, dispatch, state }, idasignacion) {
      store.commit("resetConfigAxios");
      swal({
        title: "¿Desea eliminar la evidencia?",
        text: "Confirme por favor",
        icon: "warning",
        buttons: ["cancelar", "Eliminar"],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          store.state.utiles.configAxios.url =
            store.state.utiles.apiUrl + "evidencia";
          store.state.utiles.configAxios.data = { idasignacion: idasignacion };
          store.state.utiles.configAxios.method = "DELETE";
          store.state.utiles.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token;
          swal({
            title: "Cargando...",
            text: "Por favor, espera mientras se realiza la petición.",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            dangerMode: true,
          });
          axios(store.state.utiles.configAxios)
            .then((resp) => {
              if (resp.status == 200) {
                swal({
                  title: "Evidencia eliminada!",
                  text: "Se eliminó correctamente",
                  icon: "success",
                });
                dispatch("axiosAsignaciones", {
                  page: 1,
                  itemsPerPage: state.defaultItemsPerPage,
                });
              }
            })
            .catch((error) => {
              swal({
                title: "Ocurrió un error!!",
                text: "vuelva a intentar",
                icon: "error",
              });
            });
        }
      });
    },
  },

  getters: {},
};
