<template>
  <div>
    <background-system />
    <v-row style="position: relative;" class="mx-1 mx-md-4">
      <v-col cols="12" md="10" class="pb-0 d-flex justify-space-between mt-2 mx-auto">
        <span class="title-page">
          <b>Gestion de Usuarios</b>
          <small> | Cuentas y roles </small>
        </span>

        <div class="d-flex justify-end mt-5 mt-md-0 flex-wrap">
          <router-link
            v-if="$can('create', 'usuarios')"
            :to="{ name: 'crearcuenta' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon left>mdi-account-plus</v-icon> Nuevo Usuario
            </v-btn>
          </router-link>

          <router-link
            :to="{ name: 'home' }"
            style="text-decoration: none"
            class="ma-1"
          >
            <v-btn fab x-small dense elevation="0" class="bgAzulCTM white--text"
              ><v-icon>mdi-home-circle</v-icon></v-btn
            >
          </router-link>
        </div>
      </v-col>

      <v-col cols="12" md="10" class="mx-auto">
        <v-card elevation="0">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="listUsersWithIndex"
              :loading="loading"
              :items-per-page="50"
              :options.sync="options"
              :mobile-breakpoint="0"
              :server-items-length="pagination.itemsLength"
              :footer-props="{
                'items-per-page-options': [10, 20, 30],
              }"
              hide-default-footer
            >
            <template v-slot:item.avatar="{item}">
                <v-avatar size="40" v-if="item.avatar"><v-img :src="item.avatar"></v-img></v-avatar>
                <v-avatar size="40" v-else class="bgAzulCTM white--text">{{ initialNames(item.nombre) }}</v-avatar>
            </template>
            <template v-slot:item.nombre="{item}">
                <div class="font-weight-bold text-uppercase">{{item.nombre}}</div>
                <small class="text-uppercase">{{item.cargo}}</small>
            </template>
              <template v-slot:item.rol.name="{ item }">
                <v-chip v-if="item.rol" small :class="[item.rol.name == 'Administrador'? 'yellow lighten-3 amber--text text--darken-4': 'cyan lighten-3 cyan--text text--darken-4']"
                  ><v-icon left size="20" :class="[item.rol.name == 'Administrador'? 'amber--text text--darken-4': 'cyan--text text--darken-4']">{{
                    item.rol.name == "Administrador"
                      ? "mdi-crown-circle-outline"
                      : "mdi-rocket"
                  }}</v-icon
                  >{{ item.rol.name }}</v-chip>
                  <v-chip small v-else> no rol</v-chip>
              </template>
              <template v-slot:item.email="{item}">
                <v-chip small class="clAzulCTM font-weight-bold">
                  <v-avatar left color="bgAzulCTM">
                      <v-icon color="white" small>mdi-email</v-icon>
                  </v-avatar>
                  {{item.email}}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <router-link
                  :to="{ name: 'editarcuenta', params: { id: item.iduser } }"
                  style="text-decoration: none"
                >
                  <v-btn
                    icon
                    small
                    elevation="0"
                    class="mr-2 clAzulCTM amber"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                </router-link>
                <v-btn
                    @click="suspenderUsuario(item)"
                    icon
                    small
                    elevation="0"
                    class="mr-2 white--text "
                    :class="item.estadoUser == 'active' ? 'blue-grey darken-4' : 'green accent-4'"
                    ><v-icon size="20"> {{ item.estadoUser == 'active' ? 'mdi-account-off' : 'mdi-account-check' }}  </v-icon></v-btn
                  >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import backgroundSystem from "@/components/backgroundSystem.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { onUpdated } from "vue";
import router from "@/router";

import filters from "@/Filters"

export default {
  components: { backgroundSystem },
  name: "GestionUsers",
  mixins:[
    filters
  ],
  data() {
    return {
      dialogGestion: false,
      headers: [
        {
          text: "N°",
          value: "index",
          sortable: false,
          width: "40px"
        },
         {
          text: "",
          value: "avatar",
          sortable: false,
          width: "50px"
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
          width: "350px"
        },
        {
          text: "DNI",
          value: "dni",
          sortable: false,
        },
        {
          text: "EMAIL",
          value: "email",
          sortable: false,
          width: "300px"
        },
        {
          text: "ROL",
          value: "rol.name",
          sortable: false,
          width: "100px"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 30,
        sortBy: "",
        sortDesc: "",
        groupBy: "",
        groupDesc: false,
        multiSort: false,
        mustSort: false,
      },
    };
  },
  watch: {
    options: {
      handler(pag) {
        this.getAllUser({
          page: pag.page,
          itemsPerPage: pag.itemsPerPage,
        });
        console.log(pag);
      },
    },
  },
  computed: {
    ...mapState("users", ["listusers", "pagination", "loading"]),
    listUsersWithIndex() {
      return this.listusers.map((items, index) => ({
        ...items,
        index: (this.options.page - 1) * this.options.itemsPerPage + index + 1,
      }));
    },
  },
  methods: {
    ...mapActions("users", ["getAllUser", "suspenderUsuario"]),
  },

  mounted() {
    this.getAllUser(1);
  },
  created() {
    console.log(this.$can("read", "usuarios"));
    if (!this.$can("read", "usuarios")) {
      router.push({ name: "home" });
    }
  },
};
</script>
